<template>
  <div class="text-center">
    <v-dialog
      v-model="show"
      @click:outside="close"
      max-width="500"
    >
      <v-card>
        <v-container>
            <v-row justify="center">
            <v-card-text class="text-center">
              <div v-if="!image"> Cargando comprobante </div>
              <v-img v-else   
              :src="image"  
              />
            </v-card-text>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="close"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  import FileService from "@/services/fileService"

  export default {
    props: ['visible', 'src'],

    data() {
      return {
        image: null
      }
    },

    created() {
      this.getImage()
    },

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.$emit('close')
          }
        }
      }
    },

    watch: {
      show(val) {
        if (val) {
          this.getImage()
        } else {
          URL.revokeObjectURL(this.image)
        }
      }
    },

    methods: {
      async getImage() {
        const response = await FileService.getFile(this.src)
        const blob = await response.blob()  
        this.image = URL.createObjectURL(blob);
        console.log("image", this.image)
      },

      close() {
        this.$emit('close')
      }
    },     
  }
</script>