<template>
    <v-form 
        ref="form" 
        v-model="valid"
    >
        <v-row>
            <v-col cols="12 text-h6">
                <b>Estados de cuenta </b>
            </v-col>
            <v-col
                cols="12"
                md="4"
                v-for="(month, index) in months"
                :key="index"
            >   
                <v-card>
                    <v-card-text class="p-4" 
                    style="height: 150px !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;"
                    >
                        <div class="text-h6 font-weight-bold">{{ monthsName[month] }}</div>
                        <v-file-input 
                            :ref="'fileInput' + (index + 1)"
                            variant="solo"
                            class="d-none"
                            v-model="files['bank_statement_' + (index + 1)]"
                            :rules="rules"
                            :accept="validExtensions"
                        ></v-file-input>
                        <div style="color: red;">{{ errors['bank_statement_' + (index + 1)] }}</div>
                        <v-icon 
                            large
                            @click="handleUploadClick(index + 1)"
                            v-if="!files['bank_statement_' + (index + 1)]"
                        >
                            mdi mdi-cloud-upload
                        </v-icon>
                        <div class="text-center" v-else>
                            <v-icon 
                                large
                                color="green"
                            >
                                mdi mdi-check-circle
                            </v-icon>
                            <v-col>
                                <v-btn
                                    small
                                    @click="handleUploadClick(index + 1)"
                                >
                                    Editar   
                                </v-btn>
                            </v-col>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-row>
                <ErrorMessage v-if="errorMessage" :errorMessage="errorMessage"/>
                <v-col
                cols="4"
                md="4"
                offset="4"
                >
                    <v-btn
                        block
                        elevation="2"
                        outlined
                        rounded
                        large
                        :class="!valid ? 'btn-continue-disabled' : 'btn-continue'"
                        @click="handleSubmit"
                        :disabled="!valid"
                    >
                        Continuar
                    </v-btn>
                </v-col>
            </v-row>
    </v-row>        
</v-form>  
</template>

<script>
    import RegisterService from "@/services/registerService"
    import filesRules from "@/config/filesRules"
    import ErrorMessage from "@/components/register/ErrorMessage"

    export default {

        components: {
            ErrorMessage
        },

        data() {
            return {
                files: {
                    bank_statement_1: null,
                    bank_statement_2: null,
                    bank_statement_3: null,
                },

                valid: false,

                rules: [
                    v => !!v || 'Debe cargar un archivo',
                    v => (v && v.size > 0) || 'Debe cargar un archivo',
                    v => (v && ((v.size /  Math.pow(1024,1)) < filesRules.maxSize)) || 'El archivo supera el maximo tamaña permitido: ' + filesRules.maxSize / 1024 + "MB",
                ],

                errors: {
                    bank_statement_1: null,
                    bank_statement_2: null,
                    bank_statement_3: null,
                },

                errorMessage: null,
                
                monthsName: [
                    "Enero",
                    "Febrero",
                    "Marzo",
                    "Abril",
                    "Mayo",
                    "Junio",
                    "Julio",
                    "Agosto",
                    "Septiembre",
                    "Octubre", 
                    "Noviembre",
                    "Diciembre"
                ],
                months: []
            }
        },

        mounted () {
           this.setMonths()
        },

        methods: {

            async handleSubmit () {

                this.$emit("handleLoading", true)

                if (this.files.length == 0) {
                    this.errorMessage = "Debe seleccionar un archivo"
                    return
                }

                var formdata = new FormData();

                for(let prop in this.files) {
                    formdata.append(prop, this.files[prop]);
                }

                const response = await RegisterService.step6BankStatments(formdata)
                const status = response.status
                const result = await response.json()

                this.$emit("handleLoading", false)

                console.log(result, status, "Response")
                switch (status) {
                    case 200:
                        this.$emit("handleNext")
                    break
                    case 422:
                        this.errorMessage = "Verifique los datos"
                    break
                    case 500:
                        this.errorMessage = result.msg
                    break
                }
            },

            setMonths () {
                // get the previous 3 months
                const date = new Date();
                let currentMonth = date.getMonth();  

                for(let i=1; i <= 3; i++) {
                    let month = currentMonth - i

                    if (month < 0) {
                        month = month + 12
                    }
                    
                    this.months.push(month)
                }
            },

            handleUploadClick (file_upload_id) {
                this.$refs["fileInput" + file_upload_id][0].$refs.input.click()
            },
        },

        computed: {
            validExtensions( ) {
                return filesRules.extensions.toString()
            },
            
            extensionsList () {
                return filesRules.extensionsList.toString()
            }
        },

        watch: {
            "files.bank_statement_1"() {
                if ((this.files.bank_statement_1 && ((this.files.bank_statement_1.size /  Math.pow(1024,1)) < filesRules.maxSize))) {
                    this.errors["bank_statement_1"] = null
                    return
                }  

                this.errors["bank_statement_1"] = 'El archivo supera el maximo tamaña permitido: ' + filesRules.maxSize / 1024 + "MB"
                this.files.bank_statement_1 = null
            },

            "files.bank_statement_2"() {
                if ((this.files.bank_statement_2 && ((this.files.bank_statement_2.size /  Math.pow(1024,1)) < filesRules.maxSize))) {
                    this.errors["bank_statement_2"] = null
                    return
                }  

                this.errors["bank_statement_2"] = 'El archivo supera el maximo tamaña permitido: ' + filesRules.maxSize / 1024 + "MB"
                this.files.bank_statement_2 = null
            },

            "files.bank_statement_3"() {
                if ((this.files.bank_statement_3 && ((this.files.bank_statement_3.size /  Math.pow(1024,1)) < filesRules.maxSize))) {
                    this.errors["bank_statement_3"] = null
                    return
                }  

                this.errors["bank_statement_3"] = 'El archivo supera el maximo tamaña permitido: ' + filesRules.maxSize / 1024 + "MB"
                this.files.bank_statement_3 = null
            }
        }
    }
</script>