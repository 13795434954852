<template>
<v-container id="form-container">
   <v-row class="mt-6">
      <v-col
          lg="12"
          md="12"
          >      
        <v-card class="pa-3">
          <v-row class="pa-6 align-center">
            <h3>Solicítale al cliente su <span style="color: #732C9C">Número de usuario</span> y <span style="color: #732C9C">Código de compra</span></h3>
            <v-spacer></v-spacer>
            <MenuComponent />
          </v-row>
        </v-card>
      </v-col>
   </v-row>
   <v-row>
  <!-- <v-col cols="12">
    <RewardsComponent />
  </v-col> -->

  <v-col cols="12" style="display: flex; justify-content: space-between;">
        <v-btn
            style="background: #732C9C; color: #fff"
            md
            @click="$router.push({ name: 'reserved-credit-lines' })"
            class="font-weight-bold"
        >
        Nueva venta por Líneas Reservadas
          <a 
            href="https://www.mangxo.com/help-center/suppliers"
            target="_blank"
            style="color: #fff !important;"
          >
            <v-icon 
              small 
              class="ml-2" 
              title="Haz clic y conoce como funciona."
            >mdi mdi-help-circle</v-icon>
          </a>
        </v-btn>
      <v-btn
          md
          @click="showWppPurchaseInfo = true"
          color="yellow amber white--text"
          class="font-weight-bold"
      >
        Nueva venta por Whatsapp
        <v-chip
          class="ma-2"
          color="white"
          text-color="yellow amber"
          small
        >
            <span style="color: #000 !important;">Nuevo</span>
            <v-icon right>
              mdi-star
            </v-icon>
        </v-chip>
      </v-btn>
  </v-col>
  
   </v-row>
  <v-row class="mt-6">
      <v-col
          lg="12"
          md="12"
          >      
        <v-card class="pa-6">
          <v-form
            v-model="valid"
          >
            <v-row>
              <v-col cols="6 mt-4">
                <v-text-field
                  v-model="form.user_id"
                  label="Número de usuario"
                  :rules="[v => !!v || 'El campo es requerido']"
                  required
                  @keyup="searchCustomer"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-card class="pa-3 d-flex align-center" style="height: 80%;">
                  <v-row v-if="loading == false && customer && msg ==''">
                    <v-col cols="6">
                      <h4>{{ customer.business_name }}</h4>
                      <h4>{{ customer.tax_id }}</h4>
                    </v-col>
                    <v-col cols="6" class="d-flex text-center align-center violet">
                      <h4>Crédito disponible {{ customer.available | currency }}</h4>
                    </v-col>
                  </v-row>
                  <v-row v-if="loading == false && msg != ''">
                    <v-col cols="12" class="text-center">
                      <h4>{{ msg }}</h4>
                    </v-col>
                  </v-row>
                  <v-row v-if="(loading == false && customer == null && msg == '')">
                    <v-col cols="12" class="text-center">
                      <h4>Datos del cliente</h4>
                    </v-col>
                  </v-row>
                   <v-row v-if="loading">
                    <v-col cols="12" class=" text-center">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <vuetify-money
                  v-model="form.amount"
                  label="Monto solicitado"
                  placeholder="Monto solicitado"
                  valueWhenIsEmpty=""
                  v-bind:options="options"
                  :rules="[v => !!v || 'El campo es requerido']"
                />  

                <div>Asegúrate de que el <b>monto ingresado aquí</b> coincida con el <b>monto de la factura</b> para garantizar la protección de la compra.</div>
              </v-col>
            </v-row>
            <v-text-field
              v-model="form.term"
              label="Días"
              type="number"
              :rules="[v => !!v || 'El campo es requerido']"
            ></v-text-field>

            <v-text-field
              v-model="form.reference"
              label="Referencia"
              :rules="[v => !!v || 'El campo es requerido']"
              counter="255"
            ></v-text-field>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-if="!form.is_wpp_purchase"
                  v-model="form.token"
                  label="Código de compra (Token)"
                  type="number"
                  :rules="[v => !!v || 'El campo es requerido']"
                ></v-text-field>
                </v-col>
                <v-col cols="6" class="d-flex align-center justify-end">
                <v-switch 
                  v-model="form.is_wpp_purchase"
                  style="display: inline;"
                >
                    <template v-slot:label>
                        <div class="">
                            <span :class="form.is_wpp_purchase == false ? 'v-input--switch-custom-danger' : 'v-input--switch-custom-success'">Realizar venta por Whatsapp<v-icon color="#25d366">mdi mdi-whatsapp</v-icon></span>
                        </div>
                    </template>                           
                </v-switch>
              </v-col>
            </v-row>

            <v-btn
              :disabled="!valid"
              color="yellow amber lighten-1--text"
              class="mr-4"
              @click='$emit("handleConfirm", customer.business_name)'
            >
              SOLICITAR
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
  </v-row>

  <WppPurchaseInfo 
    :visible="showWppPurchaseInfo" 
    @close="showWppPurchaseInfo = false"
  />

</v-container>
</template>

<script>

import options from '@/config/money'
import CustomerService from '@/services/customerService'
import MenuComponent  from '@/components/frontdesk/actions/MenuComponent.vue'
import WppPurchaseInfo from "@/components/frontdesk/WppPurchaseInfo.vue"
// import RewardsComponent  from '@/components/frontdesk/RewardsComponent.vue'

export default {
  props: ['value'], 

  components: {
    MenuComponent,
    WppPurchaseInfo
  },

  data: () => ({
      valid: false,
      customer: null,
      msg: "",
      loading: false,
      searchTimer: null,
      showWppPurchaseInfo: false,
      is_wpp_purchase: false
   }),

  mounted () {
    // TODO: save customer name to avoid reloads
    if (this.form.user_id) {
      this.searchCustomer()
    }
  },

  methods: {

    async searchCustomer () {

        this.customer = null
        this.msg = ""

        clearTimeout(this.searchTimer);

        let search = this.form.user_id

        if(search.length == 0) {
            return;
        }

        this.searchTimer = setTimeout( async () => {
            await this.getCustomer()
        }, 1000); 
    },

    async getCustomer() {
        this.loading = true

        const response = await CustomerService.getByUser(this.form.user_id)
        const result = await response.json()
        const status = response.status

        if (status == 200) {
          this.msg = ""
          this.customer = result.data
        }

        if (status == 404 || status == 500) {
          this.customer = null
          this.msg = result.msg
        }

        this.loading = false
    },
  },

  computed: {
    form: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value);
      }
    },
    options: () => {
      return options
    }
  },

  filters: {
        currency(value) {
            
            var formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN'
            });
            
            return formatter.format(value);
        }
    }
}

</script>

<style scoped>

@media(min-width: 1265px) {
  #form-container {
    width: 75%;
    margin:auto;
  } 
}

@media(min-width: 1600px) {
  #form-container {
    /* width: 55%; */
    margin:auto;
  } 
}


  .v-input--switch-custom-danger {
      color: #bb0404 !important;
      font-weight: 600;
      font-size: 1em;
  }

  .v-input--switch-custom-success {
      color: #1f9f02 !important;
      font-weight: 600;
      font-size: 1em;
  }

  .v-input--switch__track {
      background-color: #ccc !important;
  }

  div.v-input--switch__thumb {
      background: #bb0404 !important;
  }
  
  div.v-input--switch__thumb.primary--text {
      background: #1f9f02 !important; 
  }


</style>