<template>
    <div>
        <v-form
            ref="form"
            v-model="valid"
        >
            <v-container
                style="height: 100%;"
                class="align-center"
            >

            <h2>Representante legal</h2>
            <p>Le enviaremos el contrato al finalizar el registro</p>
            
            <v-row>
                <v-col
                cols="12"
                md="12"
                >
                <v-text-field
                    v-model="form.name"
                    label="Nombre del representante legal"
                    filled
                    :rules="rules.required"
                ></v-text-field>
                </v-col>
        
                <v-col
                cols="12"
                md="12"
                >
                    <v-text-field
                        v-model="form.last_name"
                        label="Apellido del representante legal"
                        filled
                        :rules="rules.required"
                    ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="12"
                >
                    <v-text-field
                        v-model="form.email"
                        label="Email del representante legal"
                        filled
                        :rules="rules.email"
                    ></v-text-field>
                </v-col>
        
                <v-col
                cols="12"
                md="12"
                >
                    <v-text-field
                        v-model="form.phone"
                        label="Teléfono del representante legal"
                        filled
                        :rules="rules.phone"
                    ></v-text-field>
                    <v-checkbox
                        v-model="form.optin"
                        :rules="rules.requiredCheckbox"
                    >
                        <template v-slot:label>
                            <div>
                                Declaro que el nombre, apellido, email y teléfono del representante legal son correctos. Haz clic en la casilla izquierda para confirmar.
                            </div>
                        </template>
                    </v-checkbox>
                </v-col>
                <ErrorMessage v-if="errorMessage" :errorMessage="errorMessage"/>
                <v-col
                cols="6"
                md="6"
                offset="3"
                >
                    <v-btn
                        block
                        elevation="2"
                        outlined
                        rounded
                        large
                        :class="!valid ? 'btn-continue-disabled' : 'btn-continue'"
                        @click="handleSubmit"
                        :disabled="!valid"
                    >
                        Continuar
                    </v-btn>
                </v-col>
            </v-row>
            </v-container>
        </v-form>
    </div>
</template>
<script>

    import RegisterService from "@/services/registerService"
    import ErrorMessage from "@/components/register/ErrorMessage"

    export default {
        name: "register-legal-representative",

        components: {
            ErrorMessage
        },
        
        data() {
            return {
                form: {
                    name: "",
                    last_name: "",
                    email: "",
                    phone: "",
                    optin: false
                },
                valid: false,
                rules: {
                    required: [
                        v => !!v || 'El campo es requerido',
                    ],
                    phone: [
                        v => !!v || 'El campo es requerido',
                        v => v?.length >= 10 && /[0-9-]+/.test(v) || "El número debe tener al menos 10 digitos ",
                    ],
                    requiredCheckbox: [
                        v => v === true || 'El campo es requerido',
                    ],
                    email: [
                        v => !!v || 'El campo es requerido',
                        v =>  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) ||  "Debe ingresar un correo valido"
                    ]
                },
                errorMessage: ""
            }
        },

        methods: {
            async handleSubmit() {
                this.$emit("handleLoading", true)

                const response = await RegisterService.step2(this.form)
                const status = response.status
                const result = await response.json()

                this.$emit("handleLoading", false)

                switch (status) {
                    case 200:
                        this.$router.push({ path: '/register/users' });
                    break
                    case 422:
                        this.errorMessage = "Verifique los datos"
                    break
                    case 500:
                    case 403:
                        this.errorMessage = result.msg
                    break
                }
            }
        }
    
    }

</script>
