<template>
  <div class="text-center">
   <v-dialog
      v-model="show"
      max-width="800"
    >
     <v-card>

        <iframe width="100%" height="400px" :src="map" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn
            text
            @click="show = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
    props: [
      "map",
      "visible"
    ],

    data() {
        return {}
    },

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.$emit('close')
          }
        }
      }
    }
}

</script>

<style>

@media (max-width: 998px) {
    
}

</style>