const ReservedCreditLineService = {

    url: `${process.env.VUE_APP_API_URL}reserved-credit-line`,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },

    create: async (data) => {
        try {
            const headers = ReservedCreditLineService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${ReservedCreditLineService.url}/create`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: headers
            })

            return response
        } catch (e) {
            return false;
        }
    },

    getBySupplier: async (complete = true) => {
        try {
            const headers = ReservedCreditLineService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${ReservedCreditLineService.url}/get-by-supplier/${complete}`, {
                method: "GET",
                headers: headers
            })

            return response
        } catch (e) {
            return false;
        }
    },

    deactivateLine: async (id) => {
        try {
            const headers = ReservedCreditLineService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${ReservedCreditLineService.url}/deactivate/${id}`, {
                method: "POST",
                headers: headers
            })

            return response
        } catch (e) {
            return false;
        }
    },
}

export default ReservedCreditLineService