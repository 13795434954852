const CustomerUserService = {

    url: `${process.env.VUE_APP_API_URL}customer-users`,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },

    create: async (data) => {
        try {

            const headers = CustomerUserService.headers
            headers['Register_Token'] = localStorage.getItem('register_token')

            const response = await fetch(`${CustomerUserService.url}/create`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: CustomerUserService.headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },
}

export default CustomerUserService