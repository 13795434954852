<template>
  <div class="text-center">
    <v-dialog
        v-model="show"
        max-width="400"
      >
      <v-card>
          <v-card-title>Editar pago</v-card-title>
          <v-card-text>
            
              <div>Cliente: {{ payment.customer.business_name }}</div>
              <div>Monto: ${{ payment.amount }}</div>
              <div>Plazo actual: {{ payment.term }}</div>
        
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation 
              >
              <v-row>
                <v-col
                  cols="12"
                  class="mt-4"
                >
                  <v-text-field
                    v-model="term"
                    label="Días"
                    :rules="rules.term"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="mt-4"
                >
                  <v-text-field
                    v-model="amount"
                    label="Monto"
                    :rules="rules.amount"
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <div v-if="message" class="red--text">{{message}}</div>
          </v-card-text>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="show = false"
            >
              Cerrar
            </v-btn>
            <v-btn
              text
              class="primary"
              :disabled="!valid || inSubmit"
              @click="handleSubmit"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>

import PaymentService from "../services/paymentService"

export default {
    props: [
      "payment",
      "visible"
    ],

    data() {
        return {
          term: null,
          amount: null,
          payment_id: null,
          message: "",
          valid: true,
          inSubmit: false,
          rules: {
            term: [
                v => !!v || 'El campo es requerido',  
                v => v <= this.payment.term || 'El termino debe ser menor al actual'
            ],
            amount: [
                v => !!v || 'El campo es requerido',
                v => Number(v) <= Number(this.payment.amount) || 'El monto debe ser menor al actual'
            ]
          },
        }
    },

    methods: {
      async handleSubmit () {
        const response = await PaymentService.updatePayment(this.payment_id, { term: this.term, amount: this.amount })
        const status = response.status
        const result = await response.json()
        if (status != 200) {
          this.message = result.msg
          return
        }
        
        this.$emit("showDialogAlert", result)
        this.$emit("refreshData")
        this.show = false
      },

      reset() {
        this.term = null
        this.payment_id = null
        this.message = ""
      },
    },

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.reset()
            this.$emit('close')
          }
        }
      }
    },

    watch: {
      payment(item) {
        this.payment_id = item.id
        this.term = item.term
        this.amount = item.amount
      },
    }
}

</script>

<style>

@media (max-width: 998px) {
    
}

</style>