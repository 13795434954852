const filesRules = {
    extensions: [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf"
    ],

    extensionsList: [
        "jpeg",
        "jpg",
        "png",
        "pdf"
    ],

    maxSize: 1024 * 12
}

export default filesRules