<template>
  <v-app id="inspire">
    <NavBar v-if="$route.path !=='/login' && $route.path !== '/register-customer' && !$route.path.includes('register') && $route.path !== '/register-success'  && $route.path !== '/questionnaire'  && $route.name !== 'checkout' && $route.path !== '/registrar-mis-clientes'"/>
    <router-view></router-view>
  </v-app>
</template>

<script>
import NavBar from './components/NavBar.vue'

export default {
  components: {
    NavBar
  },
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
