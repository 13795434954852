<template>
    <v-main>
        <v-container class="pa-4"> 
                <div class="text-h4">{{ texts.title }}</div>
                <div class="text-subtitle">{{ texts.subtitle }}</div>
                <v-row class="pt-6">
                    <v-col cols="12" md="4" lg="3"
                            v-for="item in items"
                            :key="item.id"
                        >
                        <v-card class="rounded-xl">
                            <v-img
                            :src="item.image_url"
                            dark
                            height="300"
                            contain
                            >
                            </v-img>

                            <v-list two-line>
                                <v-list-item>
                                    <v-list-item-content>
                                        <div class="text-h5">{{ item.name }}</div>
                        
                                        <v-list-item-title class="text-h5 mt-4"><b>{{ parseInt(item.amount) }}</b> {{ item.currency }}</v-list-item-title>
                                        <v-list-item-title class="mt-2">
                                            <v-btn 
                                                v-if="showRedeemButton"
                                                color=""
                                                outlined
                                                @click="handleClaim(item.name)"
                                                >Canjear</v-btn>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
        </v-container>
    </v-main>
</template>

<script>
export default {
    props: ["items", "currency", "texts", "showRedeemButton"],
    
    data: () => ({
        wppUrl: "https://wa.me/5218119825427?text=Hola! Quiero canjear mis puntos por: ",
    }),

    methods: {
        handleClaim (name) {
           window.open(encodeURI(this.wppUrl + name))
        }
    }
}
</script>