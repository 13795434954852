<template>
    <v-container>
        <v-col
            cols="12" 
        >
            <v-img 
                src="@/assets/logo.webp" 
                width="100"
                style="margin: auto;"
                />
        </v-col>

        <v-row
            cols="12"
            class="d-flex align-center justify-center"
            style="height: 50%;font-size: 14px;"
        >
            <div class="col-12">
                <div class="col-12 d-flex align-center mb-4">
                    <v-icon
                        medium
                        class="mr-2"
                        color="#732ed5"
                    >mdi mdi-account</v-icon>
                    <div class="violet">Tus datos</div>
                </div>
                <div class="col-12 d-flex align-center mb-4">
                    <v-icon
                        medium
                        class="mr-2"
                        :color="currentStep == 2 || currentStep == 3 ? '#732ed5' : ''"
                    >mdi mdi-file-document-outline</v-icon>
                    <div :class="currentStep == 2 || currentStep == 3 ? 'violet' : ''">Documentación</div>
                </div>
                <div class="col-12 d-flex align-center mb-4">
                    <v-icon
                        medium
                        class="mr-2"
                        :color="currentStep == 3 ? '#732ed5' : ''"
                    >mdi mdi-domain</v-icon>
                    <div :class="currentStep == 3 ? 'violet' : ''">Empresa</div>
                </div>
            </div>
        </v-row>

    </v-container>

</template>
<script>
    export default {
        name: "status-component",

        props: ["currentStep"]
    }
</script>

<style>
    .violet {
        color: #732ed5;
    }
</style>