import headersByRole from "../config/tables"
import UserManager from "@/services/manager/UserManager"

export function getHeadersPaymentsBase () {
    const role = UserManager.getRole()
    
    return headersByRole[role].payments_base
}

export function getHeadersPaymentsAll () {
    const role = UserManager.getRole()
    
    return headersByRole[role].payments_all
}

export default getHeadersPaymentsBase

