<template>
  <div class="text-center">
    <v-dialog
      v-model="show"
      @click:outside="close"
      max-width="400"
    >
      <v-card>
        <v-container v-if="message">
            <v-row justify="center">
            <v-card-text class="text-center text-h5">
              {{ message.title }}
            </v-card-text>
            <v-icon
              v-if="message.success || message.success == 'undefined'"
              color="green darken-3"
              size="100"
              weight="100"
              dark
              >
              mdi-check
            </v-icon>
            <v-icon
              v-else
              color="red darken-3"
              size="100"
              weight="100"
              dark
              >
              mdi-alert-circle
            </v-icon>
            <v-card-text class="text-center">
              {{ message.text }}
            </v-card-text>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="close"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  export default {
    props: ['visible', 'message'],

    data: () => ({
     
    }),

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.$emit('close')
          }
        }
      }
    },

    methods: {
      close() {
        this.$emit('handleCloseDialogAlert')
      }
    }
     
  }
</script>