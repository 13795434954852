<template>
  <div class="text-center">
   <v-dialog
      v-model="show"
      max-width="290"
    >
     <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ title }}
        </v-card-title>

    
        <v-card-text class="mt-4">
          {{ text }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="handleAction"
          >
            {{ text_button ?? "Aceptar" }}
          </v-btn>
           <v-btn
            color="danger"
            text
            @click="show = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
    props: [
      "title",
      "text",
      "text_button",
      "visible"
    ],

    data() {
        return {}
    },

    methods: {
      handleAction() {
        this.$emit("handleAction")
        this.$emit("close")
      }
    },

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.$emit('close')
          }
        }
      }
    }
}

</script>
