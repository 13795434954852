<template>
    <div>
        <v-container
            style="height: 100%;"
            class="align-center"
        >
            <v-col cols="12" class="text-center">
                <h2>Documentación</h2>
                <p>Carga los archivos solicitados</p>
            </v-col>  

            <router-view 
                @handleLoading="handleLoading"
                @handleNext="handleNext"
                @saveStep="saveStep"
            ></router-view>
        </v-container>
    </div>
</template>
<script>

    export default {
        name: "register-files",
        
        data() {
            return {}
        }, 

        methods: {
            handleLoading (value) {
                this.$emit("handleLoading", value)
            },

            handleNext() {
                this.$router.push({ path: '/register/legal-representative' });
            },

            saveStep (step) {
                this.$emit("saveStep", step)
            }
        }
    }

</script>
