const CheckoutService = {

    url: `${process.env.VUE_APP_API_URL}checkout`,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },

    getDetails: async (payment_code) => {
        try {
            const headers = CheckoutService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            
            const response = await fetch(`${CheckoutService.url}/get-details/${payment_code}`, {
                method: 'POST',
                headers: CheckoutService.headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },

    pay: async (payment_code) => {
        try {
            const response = await fetch(`${CheckoutService.url}/pay/${payment_code}`, {
                method: 'POST',
                headers: CheckoutService.headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    }
}

export default CheckoutService