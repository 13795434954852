<template>
    <v-main>
        <v-container
        class="py-8 px-6"
        
        >
            <v-row>
                <v-col
                lg="8"
                offset-lg="2"
                md="12"
                >
                    <v-img
                        max-height="150"
                        max-width="250"
                        src="@/assets/logo.webp"
                        class="mx-auto"
                    ></v-img>
                    <div v-if="currentQuestion == 0" class="text-h5 text-center mt-4 mb-4">Completa la siguiente encuesta para finalizar el registro</div>
                    <v-card class="pa-6 mt-6">
                        <div class="text-h5 text-center">{{ questionTitle }}</div>
                        <v-row class="mt-4 mb-4">
                            <v-col v-for="answer in answers" :key="answer.title">
                                <v-card @click="handleSelection(answer)">
                                    <v-img v-if="answer.img"
                                        :src="require(`@/assets/questionnaire/${answer.img}`)"
                                        aspect-ratio="1.4"
                                        :style="`border: ${answer.title == answerSelected.title ? '3px solid #ffc107;': ''}`"
                                    ></v-img>
                                    <div v-else class="d-flex justify-center align-center"  :style="`border: ${answer.title == answerSelected.title ? '3px solid #ffc107;': ''}; height:120px;`">
                                        <p class="ma-0 font-weight-medium">{{ answer.title }}</p>
                                    </div>
                                </v-card>
                            </v-col>    
                        </v-row>
                        
                        <v-btn
                            v-if="currentQuestion > 0"
                            color="yellow amber lighten-1--text"
                            @click="currentQuestion -= 1"
                        >
                            Anterior
                        </v-btn>
                        <v-btn
                            color="yellow amber lighten-1--text"
                            @click="handleContinue()"
                            style="margin-left: 5px !important;"
                            :disabled="!showContinueButton"
                        >
                            Continuar
                        </v-btn>
                        
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import CustomerService from '@/services/customerService'
export default {
    data() {
        return {
            form: [],
            questions: [
                {
                    title: "¿Cómo eres en un fin de semana?", 
                    answers: [
                        {title: "Muchos amigos, reuniones grandes", img: "reunion-amigos.jpeg"},
                        {title: "Solo con la novia, relax en casa", img: "solo-pareja.gif"},
                        {title: "Trabajando, distante, solo.", img: "trabajando-distante.jpeg"}
                    ]
                },
                {
                    title: "¿Qué tipo de líder eres?",
                    answers: [
                        {title: "AUTORITARIO", img: "autoritario.jpeg"},
                        {title: "DEMOCRATICO", img: "democratico.jpeg"},
                        {title: "AFILIATIVO", img: "afiliativo.jpeg"}
                    ]
                },
                {
                    title: "¿Si pudieras comprar cualquiera de estos carros con cuál te quedarías?",
                    answers: [
                        {title: "Pick up", img: "pickup.jpeg"},
                        {title: "Ferrari", img: "ferrari.jpeg"},
                        {title: "Mclaren", img: "mclaren.jpeg"}
                    ]
                },
                {
                    title: "¿Cuál eres tú?",
                    answers: [
                        {title: "Soltero", img: "soltero.jpeg"},
                        {title: "Casado sin hijos", img: "matrimonio-sin-hijos.jpeg"},
                        {title: "Casado con hijos", img: "casado-con-hijos.jpeg"}
                    ]
                },
                {
                    title: "¿Si te regalan 100,000 MXN, qué harías con ellos?",
                    answers: [
                        {title: "Comprar acciones en bolsa"},
                        {title: "Reinversión en mi negocio"},
                        {title: "Criptomonedas"}
                    ]
                }
            ],
            currentQuestion: 0,
            answerSelected: {},
            showContinueButton: false
        }
    },
    methods: {
        handleSelection (answer) {
            this.answerSelected = answer

            const _answer = {
                title: this.questionTitle,
                answer: answer.title
            }

            if (this.form.length > this.currentQuestion) {
                this.form[this.currentQuestion] = _answer
            } else {
                this.form.push(_answer)
            }

            this.showContinueButton = true
        },
        
        async handleContinue() {
            if (this.currentQuestion == this.questions.length - 1) {
                
                const data = {
                    questionnaire_token: localStorage.getItem("questionnaire_token"),
                    quiz_answers: this.form
                }

                const response = await CustomerService.saveQuizAnswers(data)
                const status = response.status

                switch(status){
                    case 200:
                        localStorage.removeItem("questionnaire_token")
                        this.$router.push({ name: 'login', params: {showMsg: true} })
                    break
                    case 404:
                    case 500:
                        console.log("Ocurrio un error")
                    break
                }

            } else {
                this.currentQuestion += 1
            }
        }
    },
    computed: {
        questionTitle () {
            return this.questions[this.currentQuestion].title
        },

        answers () {
            return this.questions[this.currentQuestion].answers
        }
    },
    watch: {
        currentQuestion() {
            this.showContinueButton = false
        }
    }
}
</script>

<style scoped>
.v-main {
    background-size: cover;
    position: relative !important; 
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size:cover;
    background-attachment: fixed;
    background-position: center;
    background-image: linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('https://mangxo.com/img/bg-header.jpeg');
}
</style>