<template>
  <div class="text-center">
    <v-dialog
        v-model="show"
        max-width="400"
      >
      <v-card>
          <v-card-title>Reservar línea</v-card-title>
          <v-card-text>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation 
            >
               <v-col
                  cols="12"
                  class="mt-4"
                >
                  <vuetify-money
                    v-model="form.amount"
                    label="Monto a reservar"
                    placeholder="Monto a reservar"
                    :rules="[v => !!v || 'El campo es requerido']"
                    v-bind:options="options"
                  />  
                  <v-text-field
                    v-model="form.days"
                    label="Días de reserva"
                    type="number"
                    :rules="[v => !!v || 'El campo es requerido']"
                  ></v-text-field>
                  <v-text-field
                    v-model="form.user_id"
                    label="Número de usuario"
                    type="number"
                    :rules="[v => !!v || 'El campo es requerido']"
                  ></v-text-field>
                   <v-text-field
                    v-model="form.token"
                    label="Código de compra (Token)"
                    type="number"
                    :rules="[v => !!v || 'El campo es requerido']"
                  ></v-text-field>
                </v-col>
            </v-form>
            <div v-if="message" class="red--text">{{message}}</div>
          </v-card-text>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="close"
            >
              Cerrar
            </v-btn>
            <v-btn
              text
              style="background: #732C9C; color: #fff"
              @click="validate"
              :disabled="loading"
            >
            {{ loading ? "Cargando..."  : "Crear" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import ReservedCreditLineService from '../../services/reservedCreditLineService'
import options from '@/config/money'

export default {
    props: [
      "visible"
    ],

    data() {
        return {
          isLoading: false,
          form: {
            amount: null,
            days: null,
            user_id: null,
            token: null,
          },

          loading: false,
          message: null,
          valid: false,
        }
    },

    methods: {

      async validate () {
        const isValid = this.$refs.form.validate()

        if (!isValid) {
            return
        }

        await this.handleSubmit()
      },
      
      async handleSubmit () {

        if (this.form.user_id == null) {
          this.message = "Debe cargar el ID de usuario"
          return
        }

        this.loading = true

        const response = await ReservedCreditLineService.create(this.form)
        const result = await response.json()
        const status = response.status

        if (status == 200) {
          this.$emit("showMessage", {success: true, msg: result.msg})
          this.close()
        } else {
          this.message = result.msg
        }

        this.loading = false
      },

      close () {
        this.reset()
        this.$emit('close')
      },

      reset() {
        this.form.amount = null
        this.form.days = null
        this.form.user_id = null
        this.form.token = null
        this.message = null
      }
    },

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.close()
          }
        }
      },
      options: () => {
        return options
      }
    }
}

</script>