const RewardService = {

    url: `${process.env.VUE_APP_API_URL}rewards`,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',

    },

    get: async (reward_program_id) => {
        try {
            const headers = RewardService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${RewardService.url}?reward_program_id=${reward_program_id}`, {
                method: 'GET',
                headers: RewardService.headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },

    getResume: async () => {
        try {
            const headers = RewardService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${RewardService.url}/get-resume`, {
                method: 'GET',
                headers: RewardService.headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },
}

export default RewardService