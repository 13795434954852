const dialogsByPaymentCode = {
    to_confirm: {
        title: "Confirmar pago",
        text: "¿Seguro que desea confirmar el pago?",
        text_button: "Confirmar"
    },
    expired: {
        title: "Impago",
        text: "¿Seguro que desea reportar el impago?",
        text_button: "Confirmar"
    }  
}

export default dialogsByPaymentCode