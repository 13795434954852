const paymentData = {
    supplier: {
        to_confirm: {
            data: [],
            loading: false,
            handleAction: "confirmPayment"
        },

        expired: {
            data: [],
            loading: false,
            handleAction: "reportNonPayment"
        },

        pending: {
            data: [],
            loading: false
        },
    },

    customer: {
        expired: {
            data: [],
            loading: false,
        },

        to_confirm: {
            data: [],
            loading: false,
        },

        pending: {
            data: [],
            loading: false,
            handleAction: "reportPayment"
        },
    }
}

export default paymentData