<template>
    <v-container class="status-mobile d-flex align-center">
        <v-row>
            <div class="col-4 text-center mb-4">
                <v-icon
                    medium
                    color="#732ed5"
                >
                    mdi mdi-account
                </v-icon>
                <div class="violet">Tus datos</div>
            </div>
            <div class="col-4 text-center mb-4">
                <v-icon
                    medium
                    :color="currentStep == 2 || currentStep == 3 ? '#732ed5' : ''"
                >mdi mdi-domain</v-icon>
                <div :class="currentStep == 2 || currentStep == 3 ? 'violet' : ''">Empresa</div>
            </div>
            <div class="col-4 text-center mb-4">
                <v-icon
                    medium
                    :color="currentStep == 3 ? '#732ed5' : ''"
                >mdi mdi-file-document-outline</v-icon>
                <div :class="currentStep == 3 ? 'violet' : ''">Documentación</div>
            </div>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        name: "status-mobile",

        props: ["currentStep"]
    }
</script>

<style>
    .violet {
        color: #732ed5;
    }

    .status-mobile {
        font-size: 0.8em;
    }
</style>