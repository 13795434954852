import VueRouter from 'vue-router'

import LoginView from './views/LoginView'
import DashboardView from './views/DashboardView'
import PaymentsView from './views/PaymentsView'
import FrontDeskView from './views/FrontDeskView'
import TokenView from './views/TokenView'
import RegisterSuccessView from './views/RegisterSuccessView'
import QuestionnaireView from './views/QuestionnaireView'
import ProfileView from './views/ProfileView'
import SuppliersView from './views/SuppliersView'
import SupplierDetailView from './views/SupplierDetailView'
import HowToUseView from './views/HowToUseView'
import WhereBuyView from './views/WhereBuyView'
import CheckoutView from './views/CheckoutView'
import ReferralsView from './views/ReferralsView'
import RewardsView from './views/RewardsView' 
import RegisterView from './views/RegisterView' 
import ReservedCreditLinesView from './views/ReservedCreditLinesView' 
// import CreateCustomerView from './views/CreateCustomerView' 
import CustomerDashboardView from "./views/CustomerDashboardView"

//Services
import UserManager from './services/manager/UserManager'

// Register
import Info from '@/components/register/Info'
import Users from '@/components/register/Users'
import Files from '@/components/register/Files'
import LegalRepresentative from "@/components/register/LegalRepresentative"
import CompanyFiles from '@/components/register/upload/CompanyFiles'
import BankStatmentFiles from '@/components/register/upload/BankStatmentFiles'
import SuccessAlert from '@/components/register/SuccessAlert'
import RegisterCustomerBySupplier from '@/views/RegisterCustomerBySupplier'



// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
  { path: '/', name: "main", meta: {needAuth: true}},
  { path: '/dashboard-customer', component: CustomerDashboardView, name: "dashboard-customer", meta: { needAuth: true, roles: ["admin-customer", "customer"] }},
  { path: '/login', component: LoginView, name: "login"},
  { path: '/questionnaire/', component: QuestionnaireView, name: "questionnaire", meta: {needQuestionnaireToken: true}},
  { path: '/register-success/', component: RegisterSuccessView, name: "register-success"},
  { path: '/dashboard-supplier', component: DashboardView, name: "dashboard-supplier", meta: { needAuth: true, roles: ["admin-supplier"] }},
  { path: '/payments/:status?', component: PaymentsView, name: "payments", meta: { needAuth: true, roles: ["admin-supplier", "vendor-supplier", "admin-customer", "customer"]}},
  { path: '/front-desk/', component: FrontDeskView, name: "front-desk", meta: { needAuth: true, roles: ["admin-supplier", "vendor-supplier"]}},
  { path: '/token/', component: TokenView, name: "token", meta: { needAuth: true, roles: ["admin-customer", "customer"]}},
  { path: '/profile/', component: ProfileView, name: "profile", meta: { needAuth: true, roles: ["admin-supplier", "admin-customer", "customer"]}},
  { path: '/suppliers/', component: SuppliersView, name: "suppliers", meta: { needAuth: true, roles: ["admin-customer", "customer"]}},
  { path: '/supplier/:id', component: SupplierDetailView, name: "supplier", meta: { needAuth: true, roles: ["admin-customer", "customer"]}},
  { path: '/how-to-use', component: HowToUseView, name: "how-to-use", meta: { needAuth: true, roles: ["admin-customer", "customer"]}},
  { path: '/where-buy', component: WhereBuyView, name: "where-buy", meta: { needAuth: true, roles: ["admin-customer", "customer"]}},
  { path: '/checkout/:payment_code', component: CheckoutView, name: "checkout", meta: { needAuth: true, roles: ["admin-customer", "customer"]}},
  { path: '/referrals', component: ReferralsView, name: "referrals", meta: { needAuth: true, roles: ["admin-supplier"]}},
  { path: '/rewards', component: RewardsView, name: "rewards", meta: { needAuth: true, roles: ["admin-customer"]}},
  { path: '/reserved-credit-lines', component: ReservedCreditLinesView, name: "reserved-credit-lines", meta: { needAuth: true, roles: ["admin-supplier" , "vendor-supplier"]}},
  { path: '/registrar-mis-clientes', component: RegisterCustomerBySupplier, name: "registrar-mis-clientes"},
  // { path: '/create-customer/:id', component: CreateCustomerView, name: "create-customer", meta: { needAuth: true, roles: ["admin-supplier" , "vendor-supplier"]}},
  { 
    path: '/register/', 
    component: RegisterView, 
    name: "register",
    meta: { needRegisterToken: false},
    children: [
      {
        path: "info",
        name: "info",
        component: Info
      },
      {
        path: "files",
        name: "files",
        component: Files,
        children: [
          {
            path: "company",
            name: "company",
            component: CompanyFiles,
          },
          {
            path: "bank-statements",
            name: "bank-statements",
            component: BankStatmentFiles,
          }
        ]
      },
      {
        path: "legal-representative",
        name: "legal-representative",
        component: LegalRepresentative
      },
      {
        path: "users",
        name: "users",
        component: Users
      },
      {
        path: "success",
        name: "success",
        component: SuccessAlert
      },
    ]
  },
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  mode: "history",
  routes, // short for `routes: routes`
})

router.beforeEach((to, from, next) => {

  if (to.matched[0].name == "main") {
    const role = UserManager.getRole()
    switch(role) {
      case "vendor-supplier":
        next({name: 'front-desk'})
      break
      case "admin-supplier":
        next({name: 'dashboard-supplier'})
      break
      case "admin-customer":
      case "customer":
        next({name: 'dashboard-customer'})
      break
    }
  }

  if (to.meta.needAuth) {
      const token = localStorage.getItem('token')
      if (token) {  
          if (to.meta.roles == undefined) { 
            next() 
          } else {
              const role = UserManager.getRole()
              if (to.meta.roles.includes(role)) {

                next()

              } else {

                switch(role) {
                  case "vendor-supplier":
                    next({name: 'front-desk'})
                  break
                  case "admin-supplier":
                    next({name: 'dashboard-supplier'})
                  break
                  case "admin-customer":
                  case "customer":
                    next({name: 'dashboard-customer'})
                  break
                }

              }
          }
        } else {
          if (to.name == "checkout") {
            localStorage.setItem("redirect_to_checkout", true)
          }

          next({name: 'login'})
        }
  } 

  // console.log(to)
  if (to.matched.length > 0) {

    if (to && to.matched[0].name == "register") {

      if (to.query.register_token) {
        localStorage.setItem("register_token", to.query.register_token)
      }

      const token = localStorage.getItem("register_token")

      // if not have token, redirect to step 1
      if (!token) {
        if (to.matched[1].name != "info") {
          localStorage.setItem("currentStep", 1)
          next({name: "info"})
          return
        } 
      } 
      
      // set status component step
      switch (to.matched[1].name){
        case "info":
          localStorage.setItem("currentStep", 1)
        break
        case "files":
          localStorage.setItem("currentStep", 2)
        break
        case "legal-representative":
        case "users":
          localStorage.setItem("currentStep", 3)
        break
      }
    } 
  }

  next()

})




export default router
