<template>
<div>
 <v-menu
    transition="slide-x-transition"
    bottom
    right
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            color="#732C9C"
            dark
            v-bind="attrs"
            v-on="on"
            >
            Acciones
            </v-btn>
        </template>

        <v-list>
            <v-list-item v-for="(item, i) in actions" :key="i">
                <v-list-item-title>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                color="#732C9C" 
                                dark
                                v-bind="attrs"
                                v-on="on"
                                @click="handleAction(item.action)"
                            >
                                {{ item.name }}
                            </v-btn>
                        </template>
                        <span style="">{{ item.tooltip }}</span>
                    </v-tooltip>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
    <RegisterComponent v-if="showAction == 'register_customer'" :visible="showAction == 'register_customer'" @close="showAction = null" @showDialogAlert="showDialogAlert"/>
    <DialogAlert :visible="dialogAlert" :message="dialogAlertContent" @handleCloseDialogAlert="handleCloseDialogAlert"/>

    
</div>
</template>

<script>
import RegisterComponent from './RegisterComponent.vue'
import DialogAlert from "@/components/DialogAlert"

export default {

    components: {
        RegisterComponent,
        DialogAlert
    },

    data: () => ({
        actions: [{
            name: "Registrar cliente", tooltip: "Si un cliente quiere comprar pero aún no utiliza Mangxo, puedes registrarlo aqui para que Mangxo lo contacte.", action: "register_customer"
        }],
        showAction: null,
        dialogAlert: false,
        dialogAlertContent: {
            title: "",
            success: "",
            text: ""
        },
    }),

    methods: {
        handleAction(actionName) {
            console.log(actionName)
            this.showAction = actionName
        },

        showDialogAlert(data) {
            this.dialogAlertContent.title = ""
            this.dialogAlertContent.success = data.success
            this.dialogAlertContent.text = data.msg
            this.dialogAlert = true
        },

        handleCloseDialogAlert () {
            this.dialogAlert = false
            this.dialogAlertContent.title = ""
            this.dialogAlertContent.success = ""
            this.dialogAlertContent.text = ""
        },
    }
    
}
</script>
