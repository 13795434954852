<template>
    <div>
        <v-form 
            ref="form" 
            v-model="valid"
        >
            <v-container
                style="height: 100%;"
                class="align-center"
            >

            <h2>Usuarios</h2>

            <h3 class="mt-4 mb-4">Indica quién administrará y comprará con Mangxo. Podrás agregar más usuarios una vez aprobada tu cuenta.</h3>
            <h3 class="mt-4 mb-4">Se enviara al correo la respuesta de la solicitud y las credenciales para ingresar a la plataforma.</h3>
        
            <v-row>
                <v-col
                cols="12"
                md="6"
                >
                    <v-text-field
                        v-model="form.name"
                        label="Nombre completo"
                        :rules="rules.required"
                        filled
                    ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="6"
                >
                    <v-text-field
                        v-model="form.last_name"
                        label="Apellido"
                        :rules="rules.required"
                        filled
                    ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="6"
                >
                    <v-text-field
                        v-model="form.email"
                        label="Email"
                        :rules="rules.email"
                        filled
                    ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="6"
                >
                    <v-text-field
                        v-model="form.phone"
                        label="Teléfono"
                        filled
                        :rules="rules.phone"
                    ></v-text-field>
                </v-col>
            </v-row>
            <ErrorMessage v-if="errorMessage" :errorMessage="errorMessage"/>
            <v-row>
                <v-col
                cols="6"
                md="6"
                offset="3"
                >
                    <v-btn
                        block
                        elevation="2"
                        outlined
                        rounded
                        large
                        :class="!valid ? 'btn-continue-disabled' : 'btn-continue'"
                        @click="handleSubmit"
                        :disabled="!valid"
                    >
                        Continuar
                    </v-btn>
                </v-col>
            </v-row>
            </v-container>
        </v-form>
    </div>
</template>
<script>

    import CustomerUserService from "@/services/customerUserService"
    import ErrorMessage from "@/components/register/ErrorMessage"

    export default {
        name: "step-4",

        components: {
            ErrorMessage
        },
        
        data() {
            return {
                form: {
                    name: "",
                    last_name: "",
                    email: "",
                    phone: ""
                },
                valid: false,
                rules: {
                    required: [
                        v => !!v || 'El campo es requerido',
                    ],
                    email: [
                        v => !!v || 'El campo es requerido',
                        v =>  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) ||  "Debe ingresar un correo valido"
                    ],
                    phone: [
                        v => !!v || 'El campo es requerido',
                        v => v?.length >= 10 && /[0-9-]+/.test(v) || "El número debe tener al menos 10 digitos "
                    ],
                },
                errorMessage: ""
                
            }
        },

        methods: {
            async handleSubmit () {
                this.$emit("handleLoading", true)

                const response = await CustomerUserService.create(this.form)
                const status = response.status
                const result = await response.json()

                this.$emit("handleLoading", false)

                switch (status) {
                    case 200:
                        this.$emit("saveStep", "step_4")
                        this.$router.push({ path: '/register/success', params: {email: this.form.email}});
                    break
                    case 422:
                        // show validation errors
                        this.errorMessage = "Verfique los datos"
                    break
                    case 500:
                    case 403:
                        this.errorMessage = result.msg
                    break
                }
            }
        }
    }

</script>

<style>
    .btn-continue {
        background: rgb(115, 46, 213);
        color: #fff !important;
        border-color: rgb(115, 46, 213);
        text-transform: capitalize !important;
        padding: 1em !important;
    }
</style>