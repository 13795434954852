<template>
    <v-main>
        <v-container
        >
            <v-row>
                <div
                    class="text-center align-end justify-center d-flex flex-column StyledReceipt"
                    style="background: rgb(115, 44, 156); height: 70vh !important; !important; color: white; width:100%;"
                    
                    >
                    <v-col
                        class="align-center justify-center d-flex flex-column"
                    >
                        <div style="width: 100%;" class="pt-5">
                            <v-img
                                width="50%"
                                src="https://liga.mangxo.com/images/logo.png"
                                class="text-center mt-4 mb-4"
                                style="margin:auto;"
                            ></v-img>
                        </div>
                        <h1 class="mt-4" v-if="!showDetailText">Confirma tu compra</h1>
                        <h1 class="mt-4" v-else>Detalle de tu compra</h1>
                        <h2 class="">{{ supplier_name }}</h2>
                    </v-col
                    >
                    <v-col class="align-center justify-start d-flex flex-column">
                        <h1 style="font-size: 3em;">$ {{ amount }}</h1>
                        <h2>Termino {{ term }} días</h2>
                        <h3 class="mt-4" v-if="showDetailText">Seras redirigido a {{ supplier_name }}</h3>
                    </v-col>
                </div>
                <v-col
                    cols="12"
                    class="align-center justify-center d-flex flex-column"
                    style="height: 30vh !important; width:100%;"
                >
                <span class="red--text"><b>{{ msg }}</b></span>


                <button :class="'order-btn' + (success ? ' animate' : '')" @click="pay" :disabled="loading">
                  <span class="default">Confirmar</span><span class="successmsg">Confirmado
                  <svg viewbox="0 0 12 10">
                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                  </svg></span>
                    <div class="box"></div>
                    <div class="truck">
                      <div class="back"></div>
                      <div class="front">
                        <div class="window"></div>
                      </div>
                      <div class="light top"></div>
                      <div class="light bottom"></div>
                    </div>
                    <div class="lines"></div>
                </button> 

                </v-col>
            </v-row>
        </v-container>
        <p v-if="loading">Cargando...</p>
    </v-main>
</template>

<script>

import CheckoutService from "@/services/checkoutService"


export default {    
    data() {
        return {
            amount: 0,
            term: 0,
            supplier_name: "",
            redirect_url: null,

            msg: "",
            payment_code: "",
            loading: false,
            success: false,
            showDetailText: false,
            styles: {
              gray: '#1a1b20',
              primary: '#275EFE',
              primaryLight: '#7699FF',
              dark: '#1a1b20',
              greyDark: '#3F4656',
              grey: '#6C7486',
              greyLight: '#CDD9ED',
              white: '#FFF',
              green: '#16BF78',
              sand: '#DCB773',
              sandLight: '#EDD9A9'
            }
        }
    },

    async mounted() {
        await this.getDetails()
    },

    methods: {
        async getDetails() {
            this.loading = true

            const paymentCode = this.$route.params.payment_code

            const response = await CheckoutService.getDetails(paymentCode)
            const status = response.status
            const result = await response.json()
            
            this.loading = false

            if (status == 200) {
                this.amount = result.data.amount
                this.term = result.data.term
                this.payment_code = result.data.payment_code
                this.supplier_name = result.data.supplier_name
                this.redirect_url = result.data.redirect_url

                return
            }

            this.msg = result.msg
        },

        async pay () {        
            this.loading = true

            const response = await CheckoutService.pay(this.payment_code)
            const status = response.status
            const result = await response.json()

            if (status == 200 && result.success) {
                this.success = true
                
                setTimeout(() => {
                  this.showDetailText = true
                },7500)

                return
            } else {
              this.msg = result.msg
              this.loading = false
            }
        },
    },
    watch: {

      showDetailText() {
        
        if (this.redirect_url) {
          setTimeout(() => {
              window.location.href = this.redirect_url
          },1000)
        }

      }
    }

}
</script>

<style scoped>

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: inherit;
}
*:before, *:after {
  box-sizing: inherit;
} 
 
.container {
  width: 30%;
  min-height: 100vh;
  font-family: Roboto, Arial;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E4ECFA;
}
.container .dribbble {
  position: fixed;
  display: block;
  right: 20px;
  bottom: 20px;
}
.container .dribbble img {
  display: block;
  height: 28px;
}

.order-btn {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  background: v-bind('styles.gray');
  position: relative;
  height: 63px;
  width: 240px;
  padding: 0;
  outline: none;
  cursor: pointer;
  border-radius: 32px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  transition: transform 0.3s ease;
}
.order-btn span {
  --o: 1;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 19px;
  line-height: 24px;
  color: v-bind('styles.white');;
  font-size: 16px;
  font-weight: 500;
  opacity: var(--o);
  transition: opacity 0.3s ease;
}
.order-btn span.default {
  transition-delay: 0.3s;
}
.order-btn span.successmsg {
  --offset: 16px;
  --o: 0;
}
.order-btn span.successmsg svg {
  width: 12px;
  height: 10px;
  display: inline-block;
  vertical-align: top;
  fill: none;
  margin: 7px 0 0 4px;
  stroke: v-bind('styles.green');;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: var(--offset);
  transition: stroke-dashoffset 0.3s ease;
}
.order-btn:active {
  transform: scale(0.96);
}
.order-btn .lines {
  opacity: 0;
  position: absolute;
  height: 3px;
  background: v-bind('styles.white');
  border-radius: 2px;
  width: 6px;
  top: 30px;
  left: 100%;
  box-shadow: 15px 0 0 v-bind('styles.white'), 30px 0 0 v-bind('styles.white'), 45px 0 0 v-bind('styles.white'), 60px 0 0 v-bind('styles.white'), 75px 0 0 v-bind('styles.white'), 90px 0 0 v-bind('styles.white'), 105px 0 0 v-bind('styles.white'), 120px 0 0 v-bind('styles.white'), 135px 0 0 v-bind('styles.white'), 150px 0 0 v-bind('styles.white'), 165px 0 0 v-bind('styles.white'), 180px 0 0 v-bind('styles.white'), 195px 0 0 v-bind('styles.white'), 210px 0 0 v-bind('styles.white'), 225px 0 0 v-bind('styles.white'), 240px 0 0 v-bind('styles.white'), 255px 0 0 v-bind('styles.white'), 270px 0 0 v-bind('styles.white'), 285px 0 0 v-bind('styles.white'), 300px 0 0 v-bind('styles.white'), 315px 0 0 v-bind('styles.white'), 330px 0 0 v-bind('styles.white');
}
.order-btn .back,
.order-btn .box {
  --start: v-bind('styles.white');
  --stop: v-bind("styles.greyLight");
  border-radius: 2px;
  background: linear-gradient(var(--start), var(--stop));
  position: absolute;
}
.order-btn .truck {
  width: 60px;
  height: 41px;
  left: 100%;
  z-index: 1;
  top: 11px;
  position: absolute;
  transform: translateX(24px);
}
.order-btn .truck:before, .order-btn .truck:after {
  --r: -90deg;
  content: "";
  height: 2px;
  width: 20px;
  right: 58px;
  position: absolute;
  display: block;
  background: v-bind('styles.white');
  border-radius: 1px;
  transform-origin: 100% 50%;
  transform: rotate(var(--r));
}
.order-btn .truck:before {
  top: 4px;
}
.order-btn .truck:after {
  --r: 90deg;
  bottom: 4px;
}
.order-btn .truck .back {
  left: 0;
  top: 0;
  width: 60px;
  height: 41px;
  z-index: 1;
}
.order-btn .truck .front {
  overflow: hidden;
  position: absolute;
  border-radius: 2px 9px 9px 2px;
  width: 26px;
  height: 41px;
  left: 60px;
}
.order-btn .truck .front:before, .order-btn .truck .front:after {
  content: "";
  position: absolute;
  display: block;
}
.order-btn .truck .front:before {
  height: 13px;
  width: 2px;
  left: 0;
  top: 14px;
  background: linear-gradient(v-bind('styles.gray'), v-bind('styles.greyDark'));
}
.order-btn .truck .front:after {
  border-radius: 2px 9px 9px 2px;
  background: v-bind('styles.primary');;
  width: 24px;
  height: 41px;
  right: 0;
}
.order-btn .truck .front .window {
  overflow: hidden;
  border-radius: 2px 8px 8px 2px;
  background: v-bind('styles.primaryLight');;
  transform: perspective(4px) rotateY(3deg);
  width: 22px;
  height: 41px;
  position: absolute;
  left: 2px;
  top: 0;
  z-index: 1;
  transform-origin: 0 50%;
}
.order-btn .truck .front .window:before, .order-btn .truck .front .window:after {
  content: "";
  position: absolute;
  right: 0;
}
.order-btn .truck .front .window:before {
  top: 0;
  bottom: 0;
  width: 14px;
  background: v-bind('styles.dark');;
}
.order-btn .truck .front .window:after {
  width: 14px;
  top: 7px;
  height: 4px;
  position: absolute;
  background: rgba(255, 255, 255, 0.14);
  transform: skewY(14deg);
  box-shadow: 0 7px 0 rgba(255, 255, 255, 0.14);
}
.order-btn .truck .light {
  width: 3px;
  height: 8px;
  left: 83px;
  transform-origin: 100% 50%;
  position: absolute;
  border-radius: 2px;
  transform: scaleX(0.8);
  background: #f0dc5f;
}
.order-btn .truck .light:before {
  content: "";
  height: 4px;
  width: 7px;
  opacity: 0;
  transform: perspective(2px) rotateY(-15deg) scaleX(0.94);
  position: absolute;
  transform-origin: 0 50%;
  left: 3px;
  top: 50%;
  margin-top: -2px;
  background: linear-gradient(90deg, #f0dc5f, rgba(240, 220, 95, 0.7), rgba(240, 220, 95, 0));
}
.order-btn .truck .light.top {
  top: 4px;
}
.order-btn .truck .light.bottom {
  bottom: 4px;
}
.order-btn .box {
  --start: v-bind('styles.sandLight');;
  --stop: v-bind('styles.sand');
  width: 21px;
  height: 21px;
  right: 100%;
  top: 21px;
}
.order-btn .box:before, .order-btn .box:after {
  content: "";
  top: 10px;
  position: absolute;
  left: 0;
  right: 0;
}
.order-btn .box:before {
  height: 3px;
  margin-top: -1px;
  background: rgba(0, 0, 0, 0.1);
}
.order-btn .box:after {
  height: 1px;
  background: rgba(0, 0, 0, 0.15);
}
.order-btn.animate .default {
  --o: 0;
  transition-delay: 0s;
}
.order-btn.animate .successmsg {
  --offset: 0;
  --o: 1;
  transition-delay: 7s;
}
.order-btn.animate .successmsg svg {
  transition-delay: 7.3s;
}
.order-btn.animate .truck {
  -webkit-animation: truck 10s ease forwards;
          animation: truck 10s ease forwards;
}
.order-btn.animate .truck:before {
  -webkit-animation: door1 2.4s ease forwards 0.3s;
          animation: door1 2.4s ease forwards 0.3s;
}
.order-btn.animate .truck:after {
  -webkit-animation: door2 2.4s ease forwards 0.6s;
          animation: door2 2.4s ease forwards 0.6s;
}
.order-btn.animate .truck .light:before, .order-btn.animate .truck .light:after {
  -webkit-animation: light 10s ease forwards;
          animation: light 10s ease forwards;
}
.order-btn.animate .box {
  -webkit-animation: box 10s ease forwards;
          animation: box 10s ease forwards;
}
.order-btn.animate .lines {
  -webkit-animation: lines 10s ease forwards;
          animation: lines 10s ease forwards;
}

@-webkit-keyframes truck {
  10%, 30% {
    transform: translateX(-164px);
  }
  40% {
    transform: translateX(-104px);
  }
  60% {
    transform: translateX(-224px);
  }
  75%, 100% {
    transform: translateX(24px);
  }
}

@keyframes truck {
  10%, 30% {
    transform: translateX(-164px);
  }
  40% {
    transform: translateX(-104px);
  }
  60% {
    transform: translateX(-224px);
  }
  75%, 100% {
    transform: translateX(24px);
  }
}
@-webkit-keyframes lines {
  0%, 30% {
    opacity: 0;
    transform: scaleY(0.7) translateX(0);
  }
  35%, 65% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100% {
    transform: scaleY(0.7) translateX(-400px);
  }
}
@keyframes lines {
  0%, 30% {
    opacity: 0;
    transform: scaleY(0.7) translateX(0);
  }
  35%, 65% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100% {
    transform: scaleY(0.7) translateX(-400px);
  }
}
@-webkit-keyframes light {
  0%, 30% {
    opacity: 0;
    transform: perspective(2px) rotateY(-15deg) scaleX(0.88);
  }
  40%, 100% {
    opacity: 1;
    transform: perspective(2px) rotateY(-15deg) scaleX(0.94);
  }
}
@keyframes light {
  0%, 30% {
    opacity: 0;
    transform: perspective(2px) rotateY(-15deg) scaleX(0.88);
  }
  40%, 100% {
    opacity: 1;
    transform: perspective(2px) rotateY(-15deg) scaleX(0.94);
  }
}
@-webkit-keyframes door1 {
  30%, 50% {
    transform: rotate(32deg);
  }
}
@keyframes door1 {
  30%, 50% {
    transform: rotate(32deg);
  }
}
@-webkit-keyframes door2 {
  30%, 50% {
    transform: rotate(-32deg);
  }
}
@keyframes door2 {
  30%, 50% {
    transform: rotate(-32deg);
  }
}
@-webkit-keyframes box {
  8%, 10% {
    transform: translateX(40px);
    opacity: 1;
  }
  25% {
    transform: translateX(112px);
    opacity: 1;
  }
  26% {
    transform: translateX(112px);
    opacity: 0;
  }
  27%, 100% {
    transform: translateX(0px);
    opacity: 0;
  }
}
@keyframes box {
  8%, 10% {
    transform: translateX(40px);
    opacity: 1;
  }
  25% {
    transform: translateX(112px);
    opacity: 1;
  }
  26% {
    transform: translateX(112px);
    opacity: 0;
  }
  27%, 100% {
    transform: translateX(0px);
    opacity: 0;
  }
}

.StyledReceipt {
  --mask: conic-gradient(from -45deg at bottom,#0000,#000 1deg 89deg,#0000 90deg) 50%/40px 100%;
  -webkit-mask: var(--mask);
          mask: var(--mask);
}

@media (max-width: 998px) {
    .container {
        width: 100%;
    }
}

@media (min-width: 999px) and (max-width: 1800px) {
    .container {
        width: 50%;
    }
}

</style>