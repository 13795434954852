<template>
<v-dialog
    v-model="show"
    max-width="500"
    >
    <v-card class="pa-6">
        <v-card-title>Registrar cliente</v-card-title>
        <v-card-text>
            <v-form 
                ref="form"
                v-model="valid"
                lazy-validation 
              >
                <v-text-field
                    v-model="form.name"
                    label="Nombre"
                    :rules="rules.required"
                    >
                </v-text-field>
                <v-text-field
                    v-model="form.contact_name"
                    label="Nombre del contacto"
                    :rules="rules.required"
                    >
                </v-text-field>
                <v-text-field
                    v-model="form.phone"
                    label="Teléfono del contacto"
                    :rules="rules.required"
                    >
                </v-text-field>
                <v-label>Si actualmente tiene una linéa de crédito con otro servicio de protección, ingresar el monto aqui (opcional)</v-label>
                 <vuetify-money
                    v-model="form.extra.credit_amount"
                    label="Monto"
                    valueWhenIsEmpty=""
                    v-bind:options="options"
                  />
                
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="show = false"
            >
              Cerrar
            </v-btn>
            <v-btn
                text
                class="primary white--text"
                @click="handleSubmit"
                :disabled="!valid || inSubmit"
            >
            Guardar
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import options from '@/config/money'
import CustomerLeadsService from "@/services/customerLeadsService"

export default {
    props: [
      "visible"
    ],

    data() {
        return {
            form: {
                name: "",
                contact_name: "",
                phone: "",
                extra: { credit_amount: ""},
                origin: "frontdesk"
            },
            inSubmit: false,
            valid: false,
            rules: {
              required: [
                 v => !!v || 'El campo es requerido',
              ]
            },
        }
    },

    methods: {
        async handleSubmit() {
          const response = await CustomerLeadsService.create(this.form)
          const status = response.status

          const data = {
            success: false,
            msg: "Ocurrio un error"
          }

          if (status == 200) {
            data.success = true
            data.msg = "Cliente registrado"
          }

          this.reset()
          this.$emit("close")
          this.$emit("showDialogAlert", data)
        },

        reset () {
          this.form.name = ""
          this.form.contact_name = ""
          this.form.phone = ""
          this.form.extra = { credit_amount: ""}
        }
    },

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.$emit('close')
          }
        }
      },
      options: () => {
        return options
      }
    },
}

</script>