<template>
  <div class="text-center">
    <v-dialog
      v-model="show"
      @click:outside="close"
      max-width="400"
    >
      <v-card>
        <v-container>
            <v-row justify="center">
            <v-card-text class="text-center text-h5">
              Condiciones
            </v-card-text>
            <v-card-text class="text-center">
              <ul>
                <li  
                  v-for="(item, i) in conditions"
                  :key="i"
                  class="mb-2"
                >
                  {{ item }}
                </li>
              </ul>
            </v-card-text>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="close"
          >
            {{ text_button ? text_button : "Ok" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  export default {
    props: ['visible', 'conditions', 'text_button'],

    data: () => ({
     
    }),

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.$emit('close')
          }
        }
      }
    },

    methods: {
      close() {
        this.$emit('handleClose')
      }
    }
     
  }
</script>