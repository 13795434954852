<template>
  <div class="text-center">
    <v-dialog
        v-model="show"
        max-width="400"
      >
      <v-card>
          <v-card-title>Solicitar extensión de plazo</v-card-title>
          <v-card-text>
            
            <div>Cliente: {{ payment.supplier.business_name }}</div>
            <div>Monto: ${{ payment.amount }}</div>
            <div>Plazo actual: {{ payment.term }}</div>
        
            <v-form>
              <v-row>
                <v-col
                  cols="6"
                  class="mt-4"
                >
                  <v-text-field
                    v-model="days"
                    label="Días"
                    required
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <div v-if="message" class="red--text">{{message}}</div>
          </v-card-text>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="show = false"
            >
              Cerrar
            </v-btn>
            <v-btn
              text
              class="primary white--text"
              @click="handleSubmit"
              :disabled="inSubmit"
            >
              {{ inSubmit ? "Cargando..." : "Confirmar" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>

import PaymentService from "../services/paymentService"

export default {
    props: [
      "payment",
      "visible"
    ],

    data() {
        return {
          days: null,
          payment_id: null,
          message: "",
          inSubmit: false
        }
    },

    methods: {
      async handleSubmit () {

        if(this.days == null || this.days == "") {
          this.message = "Debe ingresar la cantidad de días a extender"
          return
        }

        this.inSubmit = true

        const response = await PaymentService.requestExtendTerm(this.payment_id, this.days)
        const status = response.status
        const result = await response.json()

        this.inSubmit = false

        if (status != 200) {
          this.message = result.msg
          return
        }
        
        this.$emit("showDialogAlert", result)
        this.$emit("refreshData")
        this.show = false
      },
      reset() {
        this.days = null
        this.payment_id = null
        this.message = ""
      },
    },

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.reset()
            this.$emit('close')
          }
        }
      }
    },

    watch: {
      payment(item) {
        this.payment_id = item.id
      }
    }
}

</script>