<template>
    <v-main>
        <v-container fluid class="pa-4"> 
            <div class="text-h4 deep-purple--text font-weight-bold pb-6 pt-6">Proveedores de nuestra red</div>
            <v-row class="pt-6">
                <v-col cols="12" lg="3"
                        v-for="item in suppliers"
                        :key="item.id"
                    >
                    <v-card>
                        <v-img
                        :src="item.logo_url"
                        dark
                        height="300px"
                        contain
                        >
                        </v-img>

                        <v-list two-line>
                        <v-list-item>
                            <v-list-item-content>
                            <v-list-item-title class="text-h6">{{ item.business_name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                            <v-icon color="indigo">
                                mdi-phone
                            </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                            <v-list-item-title>{{ item.phone}}</v-list-item-title>
                            <v-list-item-subtitle>Teléfono</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                            <v-icon>mdi-message-text</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                        <v-divider inset></v-divider>

                        <v-list-item>
                            <v-list-item-icon>
                            <v-icon color="indigo">
                                mdi-map-marker
                            </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                            <v-list-item-title>{{ item.address }}</v-list-item-title>
                            <v-list-item-subtitle>{{ item.location }}</v-list-item-subtitle>
                            <a v-if="item.map" @click="openMap(item.map)" class="mt-4">Ver mapa</a>
                            </v-list-item-content>
                        </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <DialogMap :visible="showMap" :map="map" @close="showMap = false"/>
    </v-main>
</template>

<script>
import DialogMap from '../components/DialogMap.vue'
import SupplierService from "../services/supplierService"

export default {

    components: {
        DialogMap
    },

    data() {
        return {
            suppliers: [],
            showMap: false,
        }
    },

    async mounted() {
        const response = await SupplierService.getSuppliersToShow()

        this.suppliers = await response.json()
    },

    methods: {
        openMap (map) {
            this.showMap = true
            this.map = map
        }
    }
}
</script>