const codes = {
     "sales_receivable_amount": "Monto por cobrar",
     "sales_receivable_total": "Ventas por cobrar",
     "sales_month_total": "Cobros de este mes",
     "sales_month_amount": "Monto cobrado este mes",
     "sales_to_confirm_total": "Pagos por confirmar",
     "purchases_to_expired_total": "Compras por vencer",
     "purchases_to_pay_amount": "Monto por pagar",
     "purchases_month_total": "Compras de este mes",
     "purchases_paid_month_amount": "Monto pagado este mes",
     "purchases_expired_total": "Compras vencidas",
     "to_confirm": "A confirmar",
     "expired": "Expiradas",
     "pending": "Pendientes",
     "paid": "Pagadas",
     "non_payment": "Impagas",
     "to_confirm_singular": "A confirmar",
     "expired_singular": "Vencida",
     "pending_singular": "Pendiente",
     "paid_singular": "Pagada",
     "non_payment_singular": "Vencida",
     "paid_by_mangxo_singular": "Vencida",
     // "non_payment_singular": "Impaga",
     // "paid_by_mangxo_singular": "Cubierto por Mangxo",
     "paid_to_mangxo_singular": "Pagado a Mangxo",
     "payment_in_financing_singular": "Pago financiado",
     "rejected_singular": "Rechazado",
     // "not_reported_singular": "No reportada",
     "not_reported_singular": "Vencida",
}

export default codes