<template>
  <div class="text-center">
    <v-dialog
        v-model="show"
        max-width="400"
      >
      <v-card>
          <v-card-title>Crear pago</v-card-title>
          <v-card-text>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation 
            >
                <v-col cols="12">
                    <div>Cliente: {{ selected.business_name }}</div>
                    <div>ID de reserva: {{ selected.id }}</div>
                    <div>Monto de línea: {{ selected.amount | currency }}</div>
                    <div><b>Disponible: {{ selected.available | currency }}</b></div>
                </v-col>
        
               <v-col
                  cols="12"
                >
                  <vuetify-money
                    v-model="amount"
                    label="Monto"
                    type="number"
                    :rules="[v => !!v || 'El campo es requerido']"
                    v-bind:options="options"
                  ></vuetify-money>
                  <v-text-field
                    v-model="term"
                    label="Días"
                    type="number"
                    :rules="[v => !!v || 'El campo es requerido']"
                  ></v-text-field>
                </v-col>
            </v-form>
            <div v-if="message" class="red--text">{{message}}</div>
          </v-card-text>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="close"
            >
              Cerrar
            </v-btn>
            <v-btn
              text
               style="background: #732C9C; color: #fff"
              @click="validate"
              :disabled="loading"
            >
            {{ loading ? "Cargando..."  : "Crear" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import PaymentService from '../../services/paymentService'
import options from '@/config/money'

export default {
    props: [
      "visible",
      "selected"
    ],

    data() {
        return {
          amount: null,
          term: null,

          loading: false,
          message: null,
          valid: false,
        }
    },

    methods: {

      async validate () {
        const isValid = this.$refs.form.validate()

        if (!isValid) {
            return
        }

        await this.handleSubmit()
      },
      
      async handleSubmit () {
       
        this.loading = true

        const data = {
          amount: this.amount,
          term: this.term,
          customer_id: this.selected.customer_id,
          reserved_credit_line_id: this.selected.id
        }

        const response = await PaymentService.createOnReservedCreditLine(data)
        const result = await response.json()
        const status = response.status

        if (status == 200) {
          this.$emit("showMessage", {success: true, msg: result.msg})
          this.close()
        } else {
          this.message = result.msg
        }

        this.loading = false
      },

      close () {
        this.reset()
        this.$emit('close')
      },

      reset() {
        this.amount = null
        this.term = null
        this.customer_id = null
        this.message = null
      }
    },

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.close()
          }
        }
      },
      options: () => {
        return options
      }
    },

    filters: {
        currency(value) {
            
            var formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN'
            });
            
            return formatter.format(value);
        }
    }
}

</script>