<template>
    <div>
        <v-row class="mt-6">
            <v-col
                lg="6"
                offset="3"
                
                >
                <v-card>
                    <v-card-title>
                        <v-btn 
                            size="x-small"
                            icon="" 
                            color="black"
                            @click='$emit("goBack")'
                            title="Editar"
                        >
                            <v-icon title="Atras">mdi mdi-arrow-left</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row class="justify-center">
                            <v-btn 
                                size="x-small"
                                icon="" 
                                color="green"
                                @click='$emit("goBack")'
                                title="Editar"
                            >
                                <v-icon title="Editar">mdi-file-edit-outline</v-icon>
                            </v-btn>
                            <v-btn 
                                size="x-small"
                                icon="" 
                                color="red"
                                @click='$emit("cancel")'
                                title="Cancelar"
                            >
                                <v-icon title="Cancelar">mdi-cancel</v-icon>
                            </v-btn>
                        </v-row>
                        <v-row align="center">
                            <v-col class="text-center">
                                <div class="text-h6 mt-2"> {{ customerName }} </div>
                                <div class="text-h4 mt-2 font-weight-black">{{ form.amount | currency }}</div>
                                <div class="text-h6 mt-2">a {{ form.term }} días</div>
                                <div 
                                    v-if="delivery"
                                    class="text-h7 mt-2"
                                >
                                    Entrega en: <br>{{ address }}
                                </div>
                                <div 
                                    v-if="form.is_wpp_purchase"
                                    class="text-h7 mt-2 font-weight-bold"
                                >
                                    Venta via Whatsapp
                                </div>
                            </v-col>
                        </v-row>
                            
                        <div cols="12" 
                            class="mt-4"
                            style="display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;"
                        >
                       
                            <v-switch 
                                v-model="isConfirmed"
                                style="display: inline;"
                            >
                                <template v-slot:label>
                                    <div class="">
                                        <span :class="confirmed == '0' ? 'v-input--switch-custom-danger' : 'v-input--switch-custom-success'">He verificado la información.</span>
                                    </div>
                                </template>                           
                             </v-switch>
                             <div class="mb-6 font-weight-bold">(Haz clic en el circulo izquierdo)</div>
                            
                            <v-btn
                                :disabled="!valid || disabledButton"
                                class="yellow amber lighten-1--text"
                                @click='disabledButton = true; $emit("handleSubmit")'
                            >
                                Confirmar compra
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: ["value", "form", "confirmed", "customerName", "delivery", "address"],

    data() {
        return {
            confirm: '',
            disabledButton: false
        }
    },
    
    computed: {
      valid: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value);
        }
      },
      isConfirmed: {
        get () {
          return this.confirmed
        },
        set (confirmed) {
          this.$emit('input', confirmed);
        }
      },
    },

    filters: {
        currency(value) {
            
            var formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN'
            });
            
            return formatter.format(value);
        }
    }
}

</script>


<style scoped>
    .v-input--switch-custom-danger {
        color: #bb0404 !important;
        font-weight: 600;
        font-size: 1.2em;
    }

    .v-input--switch-custom-success {
        color: #1f9f02 !important;
        font-weight: 600;
        font-size: 1.2em;
    }

    .v-input--switch__track {
        background-color: #ccc !important;
    }

    div.v-input--switch__thumb {
        background: #bb0404 !important;
    }
    
    div.v-input--switch__thumb.primary--text {
        background: #1f9f02 !important; 
    }

</style>