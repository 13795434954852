const CustomerLeadsService = {

    url: `${process.env.VUE_APP_API_URL}customer-leads/`,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },

    create: async (data) => {
        try {
            const headers = CustomerLeadsService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${CustomerLeadsService.url}create`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: headers
            })

            return response
        } catch (e) {
            return false;
        }
    }
}

export default CustomerLeadsService