<template>
<v-main>
    <v-container
    class="py-8 px-6"
    
    >
        <v-row>
            <v-col
            cols="12"
            
            >
                <FormComponent 
                    v-if="step == 0" 
                    v-model="form"
                    @handleConfirm="handleConfirmForm"
       
                 />

                <DeliveryComponent 
                    v-if="step == 1" 
                    v-model="address"
                    @handleSkip="step = step + 1; delivery = false"
                    @handleConfirm="delivery = true; step = step + 1"
                />
                
                <CheckComponent 
                    v-if="step == 2" 
                    v-model="isConfirmed" 
                    :form="form" 
                    :customerName="customerName"
                    :delivery="delivery"
                    :address="address"
                    :confirmed="isConfirmed"
                    @handleSubmit="handleSubmit" 
                    @goBack="step = step - 2" 
                    @cancel="handleCancel"
                />

                <ResultComponent 
                    v-if="step == 3" 
                    :result="result"
                    @goBack="goToFirstStep" 
                />
            </v-col>
        </v-row>
    </v-container>
</v-main>
</template>

<script>
import FormComponent from '../components/frontdesk/FormComponent.vue'
import CheckComponent from '../components/frontdesk/CheckComponent.vue'
import ResultComponent from '../components/frontdesk/ResultComponent.vue'
import DeliveryComponent from '../components/frontdesk/DeliveryComponent.vue'
import PaymentService from '../services/paymentService'

export default {
    components: {
        FormComponent,
        CheckComponent,
        ResultComponent,
        DeliveryComponent
    },
    
    data() {
        return {
            step: 0,
            maxStep: 3,
            form: {
                user_id: null,
                amount: null,
                term: null,
                reference: null,
                token: null,
                is_wpp_purchase: false
            },
            delivery: false,
            address: "",
            isConfirmed: false,
            result: {},
            customerName: ""
        }
    },

    methods: {
        handleConfirmForm (customerName) {
            this.customerName = customerName
            this.step = this.step + 1

        },
        async handleSubmit() {
    
            let data = {
                ...this.form,
                delivery: this.delivery,
                address: ""
            }

            if(this.delivery) {
                data.address = this.address
            }

            const response = await PaymentService.create(data)
            const result = await response.json()

            this.result = result

            this.step = this.step + 1
        },

        handleCancel() {
            this.resetForm()
            this.result = {}
            this.isConfirmed = false
            this.step = 0
        },

        resetForm () {
            this.form.user_id = null
            this.form.amount = null
            this.form.term = null
            this.form.reference = null
            this.form.token = null
            this.address = ""
            this.delivery = false
        },

        goToFirstStep(reset = false) {
            this.step = 0

            if (reset) {
                this.resetForm()
            }
        }
    },
}
</script>
