<template>
  <div class="text-center">
    <v-dialog
      v-model="show"
      @click:outside="close"
      max-width="400"
    >
      <v-card v-if="item">
        <v-container v-if="item.status.name != 'paid_by_mangxo'">
              <div class="text-h4 text-center">
                {{ item.supplier.business_name ?? "-" }}
              </div>

              <v-card-text class="mt-4 text-center">
                <div class="text-h3 font-weight-bold text-center">
                  {{ item.amount | currency}}
                </div>
                <div class="text-subtitle text-center">
                  Vencimiento: {{ item.expiration_date ?? "-" }}
                </div>
                <div class="text-subtitle text-center mt-4">
                  Realiza una transferencia o deposito a la cuenta del proveedor y carga el comprobante
                </div>
                <v-row class="mt-4">
                  <v-col cols="6" v-for="account in bankAccounts" :key="account.id">
                      <v-btn
                        :color="account.bank.background"
                        class="white--text d-flex justify-between"
                        small
                        style="width: 100%; display: flex; justify-content: space-around;"
                        @click="handleShowBankAccountDetail(account)"
                      >
                        {{ account.bank.name }}

                        <div>
                          <v-img :src="account.bank.logo_url" class="bank-logo" />
                        </div>  
                      </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-form>
                  <v-file-input
                    v-if="$UserManager.getMainRole() == 'customer'"
                    accept="image/*,.pdf"
                    label="Comprobante"
                    v-model="file"
                    required
                  ></v-file-input>
                  <v-file-input
                    v-if="$UserManager.getMainRole() == 'supplier'"
                    accept="image/*,.pdf"
                    label="Comprobante"
                    v-model="file"
                  ></v-file-input>
              </v-form>
              <div class="red--text">
                {{ errorMessage }}
              </div>
        </v-container>
        <v-container v-else>
          <div class="text-h6 text-center">
            Compra cubierta por Mangxo
          </div>

          <v-card-text class="mt-4 text-center">
            <div class="text-h6">
              Deuda total
            </div>
            <div class="text-h3 font-weight-bold text-center">
              ${{ item.total_debt }}
            </div>
            <v-expansion-panels class="mt-4 mb-4">
              <v-expansion-panel>
                <v-expansion-panel-header class="font-weight-bold">  
                  Ver detalle
                </v-expansion-panel-header>
                  <v-expansion-panel-content>
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                              </th>
                              <th class="text-left">
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Monto original</td>
                              <td>{{ item.amount | currency }}</td>
                            </tr>
                            <tr>
                              <td>Vencimiento</td>
                              <td>{{ item.expiration_date }}</td>
                            </tr>
                            <tr>
                              <td>Penalización %</td>
                              <td>{{ item.penalty_percentage ?? 0 }}%</td>
                            </tr>
                            <tr>
                              <td>Penalización $</td>
                              <td>${{ item.penalty_amount ?? 0}}</td>
                            </tr>
                            <tr>
                              <td>Días de atraso</td>
                              <td>{{ (item.remaining_days * -1) ?? 0 }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <div class="text-subtitle text-center mt-4">
              Realiza una transferencia o deposito a la cuenta de <b>Mangxo</b> y carga el comprobante
            </div>
            <v-row class="mt-4">
              <v-col cols="12">
                <div class="text-h5 font-weight-bold">BBVA</div>
                <div class="text-h6">
                  <div class="font-weight-bold">Cuenta</div>
                  {{mangxoAccount}}
                  <CopyBtn 
                    :content="mangxoAccount"
                  />
                </div>
                <div class="text-h6">
                  <div class="font-weight-bold">
                    CLABE 
                  </div>
                  {{mangxoClabe}}
                  <CopyBtn 
                  :content="mangxoClabe"
                />
                </div>
              </v-col>
            </v-row>

          </v-card-text>

          <v-divider></v-divider>
          <v-form>
              <v-file-input
                v-if="$UserManager.getMainRole() == 'customer'"
                accept="image/*,.pdf"
                label="Comprobante"
                v-model="file"
                required
              ></v-file-input>
              <v-file-input
                v-if="$UserManager.getMainRole() == 'supplier'"
                accept="image/*,.pdf"
                label="Comprobante"
                v-model="file"
              ></v-file-input>
          </v-form>
          <div class="red--text">
            {{ errorMessage }}
          </div>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="success"
            @click="handleSubmit"
            :disabled="inSubmit"
          >
          {{ inSubmit ? "Cargando..."  : "Confirmar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <BankAccountDetail 
      :visible="showBankAccountDetail" 
      :account="selectedAccount"
      @close="handleShowBankAccountDetailClose"
    />
  </div>
</template>

<script>
  import PaymentService from "../services/paymentService"
  import SupplierService from "../services/supplierService"
  import BankAccountDetail from "@/components/reportPayment/BankAccountDetail"
  import CopyBtn from "@/components/CopyBtn"
  
  export default {
    props: ['visible', 'item'],

    components:{
      BankAccountDetail,
      CopyBtn
    },

    data: () => ({
        file: null,
        errorMessage: '',
        bankAccounts: [],
        selectedAccount: null,
        showBankAccountDetail: true,
        inSubmit: false,
        mangxoAccount: "0120502944",
        mangxoClabe: "012580001205029445",
    }),

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.$emit('close')
          }
        }
      }
    },

    methods: {
      async handleSubmit() {

          if (this.$UserManager.getMainRole() == 'customer' && this.file == null) {
            this.errorMessage = "Debe seleccionar un archivo"
            return
          }

          this.inSubmit = true 

          const response = await PaymentService.reportPayment(this.item.id, this.file)
          const status = response.status
          const result = await response.json()

          switch(status) {
            case 200:
              if (!result.success) {
                this.errorMessage = result.msg
                return
              }

              this.inSubmit = false
              this.$emit("showDialogAlert", result)
              this.$emit("refreshData")
              this.close()
            break
            case 500:
              this.errorMessage = "Ocurrio un error"
            break
            case 404:
              this.errorMessage = result.msg
            break
            case 422:
              this.errorMessage = "Debe cargar un archivo"
            break
          }

          this.inSubmit = false

      },

      async getBankAccountsBySupplier () {
        const response = await SupplierService.getBankAccountsBySupplier(this.item.supplier_id)
        const status = response.status
        const result = await response.json()

        if (status == 200) {
          this.bankAccounts = result
        }

        console.log(this.bankAccounts, "bank accounts") 
      },

      // getImgUrl (image) {
      //   return require("@/assets/banks/" + image)
      // },

      handleShowBankAccountDetail (account) {
        this.selectedAccount = account
        this.showBankAccountDetail = true
      },

      handleShowBankAccountDetailClose () {
        this.selectedAccount = null
        this.showBankAccountDetail = false
      },

      close() {
        this.valid = false
        this.file = null
        this.errorMessage = null
        this.bankAccounts = []
        this.$emit('close')
      },
    },

    filters: {
        currency(value) {
            
            var formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN'
            });
            
            return formatter.format(value);
        }
    },

    watch: {
      async show(val) {
        if (val) {
          // If the purchase has already been covered, the supplier's bank details should not be shown, only mangxo
          if (this.item.status.name != "paid_by_mangxo") {
            await this.getBankAccountsBySupplier()
          }
        }
      }
    },
     
  }
</script>

<style>
  .bank-logo {
    width: 20px !important;
    margin: auto;
    /* border-radius: 1em; */
    margin-left: 1em;
  }
</style>