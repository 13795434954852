<template>
    <v-main>    
        <v-container class="mt-9">
            <v-row>
                <v-col
                    cols="12"
                    >
                    <v-card class="pa-5 rounded-xl">
                        <v-card-title class="d-none d-sm-block">
                            <div class="text-h4" style="color: #732C9C;"><b>Mangxo Rewards </b></div>
                            <div class="text-subtitle-1">Nuestros programas de fidelización estan diseñados especialmente para agradecer tu lealtad <b>¡Descubre un mundo de recompensas exclusivas!</b></div>
                        </v-card-title>
                         <v-card-title class="d-flex d-sm-none">
                            <div class="text-h4 mb-2" style="color: #732C9C;"><b>Mangxo Rewards</b></div>
                            <div class="text-subtitle-1">Nuestros programas de fidelización estan diseñados especialmente para agradecer tu lealtad <br><b> ¡Descubre un mundo de recompensas exclusivas! </b></div>
                        </v-card-title>
                        <v-card-text>
                            <v-row align="center">
                                <v-col
                                cols="12"
                                lg="12"
                                >   
                                    <v-row>
                                        <v-col cols="12" lg="6">
                                            <div class="text-h5 mt-4" style="color: #732C9C;"><b>Mangxo Points</b></div>
                                            <div class="text-h6">Todas tus compras acumulan puntos para canjear en nuestro catalogo.</div>
                                            <a @click="showConditions(2)">Ver condiciones</a>
                                        </v-col>
                                        <v-col cols="12" lg="6">
                                            <div class="text-h5 mt-4" style="color: #732C9C;"><b>Mangxo Referrals</b> </div>
                                            <div class="text-h6">Refiere a otros constructores y obtén recompensas automaticamente.</div>
                                            <a @click="showConditions(1)">Ver condiciones</a>  
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-chip
                                                style="background: #732C9C; padding: 1.2em !important;"
                                                text-color="white"
                                                class="mt-4"
                                            >
                                                Código de referido: <b>{{ $UserManager.get().referral_code_to_share }}</b>
                                                <v-icon right color="white">
                                                    mdi-bullhorn-outline
                                                </v-icon>
                                            </v-chip>   
                                            <!-- <v-chip
                                                color="primary"
                                                text-color="white"
                                                class="mt-4 ml-lg-2"
                                                style="padding: 1.2em !important;"
                                                @click="copyData"
                                            >
                                                <b>Compartir enlace de referidos</b>
                                                <v-icon right color="white">
                                                    mdi-clipboard
                                                </v-icon>
                                            </v-chip>   
                                            <div v-if="showCopyMsg" class="mt-4" style="color: #732C9C;"><b>Enlace copiado</b></div> -->
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="">
                <v-col
                    lg="4"
                    cols="12"
                    >
                    <v-card class="rounded-xl">
                        <v-card-text>
                            <v-row align="center">    
                                <v-card-title>
                                    Puntos
                                </v-card-title>
                                <v-card-text>
                                    <v-row align="center">
                                        <v-col
                                        cols="12"
                                        >
                                        <div class="text-h3 font-weight-bold primary--text">
                                        {{ resume.points }}
                                        </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col
                    cols="12"
                    lg="4"
                    >
                    <v-card class="rounded-xl">
                        <v-card-text>
                            <v-row align="center">    
                                <v-card-title>
                                    Referidos
                                </v-card-title>
                                <v-card-text>
                                    <v-row align="center">
                                        <v-col
                                        cols="4"
                                        >
                                            <div class="text-h3 font-weight-bold primary--text">
                                            {{ resume.referrals }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                   <v-col
                    cols="12"
                    lg="4"
                    >
                    <v-card class="rounded-xl" :color="resume.currentReferralReward ? '#732C9C' : '#607D8B'" height="100%">
                        <v-card-text>
                            <v-row align="center">    
                                <v-card-title class="white--text">
                                    {{ resume.currentReferralReward ? "Has obtenido: " : "Refiere a un constructor y obtén tu primera recompensa"}}
                                </v-card-title>
                                <v-card-text>
                                    <v-row align="center">
                                        <v-col
                                        cols="12"
                                        >
                                            <div class="text-h6 font-weight-bold white--text">
                                            {{ resume.currentReferralReward != 0 ? resume.currentReferralReward : "" }}
                                            <v-icon right color="white" v-if="resume.currentReferralReward">
                                                mdi-cake-variant
                                            </v-icon>   
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
             <v-row class="mt-4">
                <v-col
                    cols="12"
                    >
                    <v-btn   
                        color="primary"
                        outlined
                        large
                        class="rounded-xl mt-2"
                        @click="handleShowCatalog(2)"
                    >
                    Ver catalogo de puntos
                    </v-btn>
                    <v-btn   
                        color="primary"
                        outlined
                        large
                        class="rounded-xl mt-2 ml-lg-2"
                        @click="handleShowCatalog(1)"
                    >
                    Ver premios por referidos
                    </v-btn>
                </v-col>
            </v-row>
            <ConditionsRewardModal :visible="showConditionsModal" :conditions="conditions" @handleClose="showConditionsModal = false"/>
            <v-row  ref="rewardsCatalogRef">
                <RewardsCatalog 
                    v-if="showCatalog" 
                    :items="rewards"
                    :texts="catalogTexts"
                    :showRedeemButton="showRedeemButton"
                />
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import RewardsCatalog from "../components/RewardsCatalog"
import RewardService from "../services/rewardService"
import ConditionsRewardModal from "../components/ConditionsRewardModal.vue"

export default {

    components: {
      RewardsCatalog,
      ConditionsRewardModal
    },

    data: () => ({
       rewards: [],
       resume: {
        points: "-",
        referrals: "-",
        currentReferralReward: null,
        programs: []
       },
       showCatalog: false,
       reward_program_id: null,
       loading: false,
       catalogTexts: {
           title: "",
           subtitle: ""
       },
       showRedeemButton: false,
       showCopyMsg: false,
       urlRegister: "https://app.mangxo.com/register/step-1",
       showConditionsModal: false,
       conditions: []
    }),

    async created() {
        await this.getResume()
    },

    methods: {
        async getResume () {
            this.loading = true

            const response = await RewardService.getResume()
            const status = response.status
            const result = await response.json()
            
            if (status != 200) {
                console.log("Ocurrio un error")
                return
            }

            this.resume = result
            this.loading = false
        },

        async getRewards() {
            this.loading = true

            const response = await RewardService.get(this.reward_program_id)
            const status = response.status
            const result = await response.json()
            
            if (status != 200) {
                console.log("Ocurrio un error")
                return
            }

            this.rewards = result
            this.loading = false
        },

        handleShowCatalog (reward_program_id) {

            if (reward_program_id == 1) {
                this.catalogTexts.title = "Premios por referir a otros constructores"
                this.catalogTexts.subtitle = "¡Te enviaremos el premio automáticamente cuando cumplas con los objetivos!"
                this.showRedeemButton = false
            }

            if (reward_program_id == 2) {
                this.catalogTexts.title = "Catalogo de productos"
                this.catalogTexts.subtitle = "¡Canjea tus puntos Mangxo!" 
                this.showRedeemButton = true  
            }

            this.showCatalog = true
            this.reward_program_id = reward_program_id

            const rewardsCatalogRef = this.$refs.rewardsCatalogRef
            if (rewardsCatalogRef) {
                rewardsCatalogRef.scrollIntoView({ behavior: 'smooth'})
            }
        },

        copyData() {
            const data = this.urlRegister + this.$UserManager.get().referral_code_to_share
            navigator.clipboard.writeText(data);

            this.showCopyMsg = true;
        },

        showConditions (reward_program_id) {
            const program = this.resume.programs.find(e => e.id == reward_program_id)

           if (!program) {
               return
           }

            this.conditions = JSON.parse(program.conditions)
            this.showConditionsModal = true
        }
    },

    watch: {
        async reward_program_id() {
            await this.getRewards()
        }
    },
}
</script>

