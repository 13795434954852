<template>
    <v-card class="d-flex align-center" style="min-height: 120px;">
        <v-col cols="2" class="text-center">
            <v-avatar
                class="purchase-card-img"
                size="64px"
                style="border: 1px solid #ccc;"
                >
                <img
                    :src="item.supplier.logo_url"
                >
            </v-avatar>
            <div class="caption font-weight-bold">ID: {{ item.id }}</div>
        </v-col>
        <v-col cols="6" class="purchase-info">
            <div>
                <span v-if="item.status.name == 'paid_by_mangxo'" class="font-weight-bold">{{item.total_debt }}</span> 
                <span v-else class="font-weight-bold">{{item.amount | currency}}</span> 
                en {{ item.supplier.business_name }}
            </div>
            <div v-if="item.remaining_days == 0">
                <v-chip
                    text-color="white"
                    small
                    color="rgb(231, 58, 58)"
                    class="mb-2"
                >
                    Vence hoy
                    <v-icon right size="12">
                        mdi-clock
                    </v-icon>
                </v-chip>
            </div>
            <div style="font-size: 0.8em;" v-if="item.remaining_days < 0 || item.status.name == 'paid_by_mangxo'">
                Venció el <span class="red--text">{{ item.expiration_date }}</span>
            </div>
            <div v-else>
                Vence el <b>{{ item.expiration_date }}</b>
            </div>
            <div v-if="item.status.name != 'paid_by_mangxo'">
                <v-chip
                    color="orange"
                    text-color="white"
                    small
                    v-if="item.pending_request_extend_term"
                >
                    Exensión pendiente
                    <v-icon right size="12">
                        mdi-clock
                    </v-icon>
                </v-chip>
                <div v-if="!item.pending_request_extend_term && item.last_extension_term">
                    <v-chip
                        :color="item.last_extension_term.accepted ? 'green' : 'red'"
                        text-color="white"
                        small
                    >
                        {{ item.last_extension_term.accepted ? ("Extendido " + item.last_extension_term.days + " días") : "Extension rechazada"  }}
                        <v-icon right size="12">
                            mdi-clock
                        </v-icon>
                    </v-chip>
                </div>
            </div>
            <v-chip
            text-color="white"
            small
            color="rgba(231, 58, 0.5)"
            v-if="item.status.name == 'paid_by_mangxo'"
            >
                Penalización {{ item.penalty_percentage ?? "0" }}%
                <v-icon right size="12">
                    mdi-arrow-up
                </v-icon>
            </v-chip>
        </v-col>
        <v-col cols="4">
            <v-btn 
                small 
                block 
                primary
                style="background-color: rgb(143, 57, 193); color: #fff;"
                elevation="0"
                @click="handleAction(item, 'pending')"
            >
            Pagar
            </v-btn>
            <v-btn 
                v-if="!item.pending_request_extend_term && (item.status_id.name == 'pending' || item.status_id.name == 'expired' || item.status_id.name == 'non_payment' || item.status_id.name == 'not_reported')"
                small 
                class="mt-2" 
                block 
                style="background-color: rgb(143, 57, 193); color: #fff;"
                elevation="0"
                @click="handleAction(item, 'request_extend_term')"
            >
                Extender
            </v-btn>
            <v-btn 
                small 
                block 
                primary
                class="mt-2"
                style="background-color: rgb(143, 57, 193); color: #fff;"
                elevation="0"
                @click="handleAction(item, 'show_invoice')"
                v-if="item.invoice_url"
            >
            Ver factura
            </v-btn>
        </v-col>
    </v-card>
</template>

<script>
    export default {
        props: ["item"],

        data() {
            return {}
        },

        methods: {
            handleAction(item, code) {
                this.$emit("handleAction", item, code)
            },
        },

        filters: {
            currency(value) {
                
                var formatter = new Intl.NumberFormat('es-MX', {
                    style: 'currency',
                    currency: 'MXN'
                });
                
                return formatter.format(value);
            }
        }
    }
</script>

<style>
    @media(max-width: 998px) {
        .purchase-card-img {
            min-width: 48px !important;
            width: 48px !important;
            height: 48px !important;
        }

        .purchase-info {
            font-size: 0.8em !important;
        }
    }
 
</style>