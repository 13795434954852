<template>
    <div>
        <DialogComponent :visible="showDeleteDialog" @close="showDeleteDialog = false" title="Eliminar" text="¿Seguro que desea eliminar el token compartido?" text_button="Confirmar" @handleAction="deleteSharedToken"/>
        <v-expansion-panels class="mt-4">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <div>
                        <b>Código de compra compartido</b>
                        <span v-if="$UserManager.get().use_shared_token"><v-icon color="success" class="ml-2" small>mdi mdi-check-circle</v-icon>Activo</span>
                        <span v-else class="ml-4">No activado</span>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content v-if="!$UserManager.get().use_shared_token">
                    <v-btn 
                        primary
                        @click="handleGenerateSharedToken"
                        :disabled="inSubmit"
                        v-if="!inSubmit"
                    >
                        Generar
                    </v-btn>
                    <v-progress-circular
                        v-else
                        indeterminate
                        color="deep-purple"
                    >
                    </v-progress-circular>

                    <v-row class="justify-content-center mt-2">
                        <v-col cols="12">
                            <div style="color: red;">{{errorMessage}}</div>
                        </v-col>
                    </v-row>

                    <p class="mt-4">
                        El código de compra compartido permite que el proveedor registre compras a tú nombre sin necesidad de compartirle tu número de usuario y código de compra dinámico
                    </p>
                    <p>
                        Solo el proveedor al que se lo compartas podrá realizar dicha acción
                    </p>
                </v-expansion-panel-content>
                <v-expansion-panel-content v-else>
                    <v-row class="justify-content-center">
                        <v-col cols="12">
                            <span style="letter-spacing: 0.5em; font-size: 1.5em; font-weight: bold;">{{ token }}</span>
                        </v-col>
                    </v-row>
                    <v-row class="justify-content-center">
                        <v-col cols="12">
                            <v-btn
                                :color="copyButton.color"
                                outlined
                                @click="copyToken"
                            >
                                <v-icon
                                    color="light"
                                >
                                    mdi {{ copyButton.icon }}
                                </v-icon>
                                    {{ copyButton.text }} 
                            </v-btn> 
                        </v-col>
                    </v-row>
                    <v-row class="mt-5">
                        <v-col cols="12 text-left">
                            <v-icon 
                                color="red lighten-2"
                                @click="showDeleteDialog = true"
                                v-if="!inSubmit"
                            >
                                mdi mdi-delete
                            </v-icon>
                            <v-progress-circular
                                v-else
                                indeterminate
                                color="deep-purple"
                            >
                            </v-progress-circular>
                            <v-row class="justify-content-center mt-2">
                                <v-col cols="12">
                                    <div style="color: red;">{{errorMessage}}</div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>

import SharedTokenService from '../services/sharedTokenService'
import DialogComponent from './DialogComponent.vue'

export default {

    components: {
        DialogComponent
    },

    data() {
        return {
            token: null,
            inSubmit: false,
            showDeleteDialog: false,
            errorMessage: "",
            copyButton: {
                text: "Copiar código",
                color: "light",
                icon: "mdi-clipboard"
            }
        }
    },

    mounted () {
        if (this.$UserManager.get().use_shared_token) {
            this.token = this.$UserManager.get().shared_token
        }
    },

    methods: {
        async handleGenerateSharedToken() {
            this.inSubmit = true

            const response = await SharedTokenService.create()
            const status = response.status
            const result = await response.json()

            if (status == 200) {
                this.$UserManager.update({
                    shared_token: result.token,
                    use_shared_token: true
                })

                this.token = result.token

                this.$forceUpdate(); // TODO: Improve
            } else {
                this.errorMessage = result.msg
            }

            this.inSubmit = false
        },

        async deleteSharedToken () {

            this.inSubmit = true

            const response = await SharedTokenService.delete()
            const status = response.status
            const result = await response.json()

            if (status == 200) {
                
                this.$UserManager.update({
                    shared_token: null,
                    use_shared_token: false
                })

                this.token = null

                this.resetCopyToken()
            } else {
                this.errorMessage = result.msg
            }

            this.inSubmit = false
        },

        copyToken() {
            const data = this.token
            navigator.clipboard.writeText(data);
            
            this.copyButton.text = "Código copiado"
            this.copyButton.color = "success"
            this.copyButton.icon = "mdi-clipboard-check"
        },

         resetCopyToken() {
            this.copyButton = {
                text: "Copiar código",
                color: "light",
                icon: "mdi-clipboard"
            } 
        }
    }

}
</script>