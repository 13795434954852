<template>
    <v-main>

        <SuppliersCarousel v-if="$UserManager.getMainRole() == 'customer'"/>

        <v-container
        class="py-8 px-6"
        
        >

        <v-row>
            <v-col
            cols="12"
            >
            <v-row no-gutters>
                <div class="text-h4 deep-purple--text font-weight-bold">{{ $UserManager.get().business_name }}</div>
                <v-spacer></v-spacer>
            </v-row>
            </v-col>
        </v-row>
        <v-row class="pt-6 pb-6" v-if="$UserManager.getMainRole() == 'customer'">
            <v-col cols="12">
                <div class="text-h3 font-weight-bold primary--text">
                    {{  availableAmount | currency }}
                </div>
                <div class="text-h5 font-weight-bold primary--text">
                    Disponible
                </div>
            </v-col>
            <v-col cols="12" md="3"  v-if="$UserManager.getMainRole() == 'customer'">
                <CreditExtensionToggle 
                    @showDialogAlert="showDialogAlert"
                />
            </v-col>
        </v-row>

        <v-expansion-panels class="mt-4 mb-4">
            <v-expansion-panel>
              <v-expansion-panel-header class="font-weight-bold">  
                Métricas
              </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-col
                            cols="0"
                            md="12"
                            lg="4"
                            v-for="(value, key, index) in resume"
                            :key="key"
                            >
                            <v-card
                                elevation="0"
                                style="border: 1px solid #ccc"
                            >
                                <v-card-title>
                                    {{ translate_code(key) }}
                                </v-card-title>
                                <v-card-text>
                                    <v-row align="center">
                                        <v-col
                                        cols="12"
                                        >
                                        <div class="text-h4 font-weight-bold primary--text" v-if="index % 2 != 0">
                                        {{  value | currency }}
                                        </div>
                                        <div class="text-h4 font-weight-bold primary--text" v-else>
                                        {{  value }}
                                        </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-row v-if="paymentsReported.length > 0">
            <v-col cols="12">
                <v-row>
                    <v-col cols="12">
                        <h2>Compras cubiertas por Mangxo</h2>
                        <p>Paga ahora para volver a comprar a crédito con todos los proveedores de la red</p>
                    </v-col>
                </v-row>
                <v-row v-if="!loading">
                    <v-col cols="12" md="6" v-for="payment in paymentsReported" :key="payment.id">
                        <PurchaseCard 
                            :item="payment"
                            @handleAction="handleActionPayment"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-btn
                            style="border: 1px solid rgb(143, 57, 193); color: rgb(143, 57, 193); font-weight: 800;"
                            block
                            outlined
                            @click="$router.push({ name: 'payments', params: {status: 'paid_by_mangxo'} })"
                        >
                            Ver todas
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="d-flex justify-center" v-else>
                    <v-progress-circular
                    :size="70"
                    :width="7"
                    color="rgb(143, 57, 193)"
                    indeterminate
                    ></v-progress-circular>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-if="paymentsExpired.length > 0">
            <v-col cols="12">
                <v-row>
                    <v-col cols="12">
                        <h2 class="font-weight-bold" style="color: rgb(231, 58, 58)">Compras vencidas</h2>
                        <p>Paga ahora y evita penalizaciones</p>
                    </v-col>
                </v-row>
                <v-row v-if="!loading">
                    <v-col cols="12" md="6" v-for="payment in paymentsExpired" :key="payment.id">
                        <PurchaseCard 
                            :item="payment"
                            @handleAction="handleActionPayment"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-btn
                            style="border: 1px solid rgb(143, 57, 193); color: rgb(143, 57, 193); font-weight: 800;"
                            block
                            outlined
                            @click="$router.push({ name: 'payments' })"
                        >
                            Ver todas
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="d-flex justify-center" v-else>
                    <v-progress-circular
                    :size="70"
                    :width="7"
                    color="rgb(143, 57, 193)"
                    indeterminate
                    ></v-progress-circular>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12">
                        <h2>Compras por vencer</h2>
                    </v-col>
                </v-row>
                <v-row v-if="payments.length == 0"> 
                    <v-col cols="12">
                        <h4>Aún no tienes compras por vencer</h4>
                    </v-col>
                </v-row>
                <v-row v-if="!loading">
                    <v-col cols="12" md="6" v-for="payment in payments" :key="payment.id">
                        <PurchaseCard 
                            :item="payment"
                            @handleAction="handleActionPayment"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-btn
                            style="border: 1px solid rgb(143, 57, 193); color: rgb(143, 57, 193); font-weight: 800;"
                            block
                            outlined
                            @click="$router.push({ name: 'payments', params: {status: 'pending'} })"
                        >
                            Ver todas
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="d-flex justify-center" v-else>
                    <v-progress-circular
                    :size="70"
                    :width="7"
                    color="rgb(143, 57, 193)"
                    indeterminate
                    ></v-progress-circular>
                </v-row>
            </v-col>
        </v-row>
    </v-container>

    <ReportPaymentDialog 
        :visible="reportPaymentDialog" 
        :item="item" 
        @close="item = null; reportPaymentDialog = false" 
        @showDialogAlert="showDialogAlert" 
        @refreshData="init"
    />
    <DialogAlert 
        :visible="dialogAlert" 
        :message="dialogAlertContent" 
        @handleCloseDialogAlert="handleCloseDialogAlert"
    />
    <DialogRequestExtendTerm 
        :payment="item" 
        :visible="showDialogRequestExtendTerm" 
        @handleCloseDialogAlert="handleCloseDialogAlert" 
        @close="showDialogRequestExtendTerm = false"  
        @showDialogAlert="showDialogAlert" 
        @refreshData="init"
    />
    
</v-main>
</template>

<script>
import PaymentService from "../services/paymentService"
import translate_code from "../helpers/translate_code"
// import TableComponent from "../components/TableComponent.vue"
// import DialogComponent from "../components/DialogComponent"
import ReportPaymentDialog from "../components/ReportPaymentDialog"
import DialogAlert from "../components/DialogAlert"
import getDialog from "../helpers/dialogs"
// import DialogExtendTerm from '@/components/DialogExtendTerm.vue'
// import DialogFile from "../components/DialogFile"
import DialogRequestExtendTerm from "../components/DialogRequestExtendTerm"
// import ExtendTermTable from "../components/ExtendTermTable.vue"
import CreditExtensionToggle from '@/components/creditExtension/Toggle.vue'
import SuppliersCarousel from "@/components/SuppliersCarousel.vue"
import PurchaseCard from "@/components/customer-dashboard/PurchaseCard.vue"
import FileService from "@/services/fileService"

export default {

    components: {
        // TableComponent,
        // DialogComponent,
        ReportPaymentDialog,
        DialogAlert,
        // DialogExtendTerm,
        // DialogFile,
        DialogRequestExtendTerm,
        // ExtendTermTable,
        CreditExtensionToggle,
        SuppliersCarousel,
        PurchaseCard
    },

    data: () => ({
        resume: {},
        availableAmount: 0,
        payments: [],
        paymentsExpired: [],
        paymentsReported: [],
        loading: false,
        dialogContent: {
            title: "",
            text: "",
            text_button: "",
            action: () => {}
        },
        dialog: false,
        dialogAlert: false,
        dialogAlertContent: {
            title: "",
            success: "",
            text: ""
        },
        reportPaymentDialog: false,
        item: null, //only use in reportPayment
        showDialog: false,
        showDialogExtendTerm: false,
        showDialogFile: false,
        showDialogRequestExtendTerm: false,
        totalPayments: "-"
    }),

    created() {

    },

    async mounted() {
       await this.init()
    },

    methods: {

        async init () {
            await this.getResume()
            await this.getPaymentsPending()
            await this.getPaymentsExpired()
            await this.getPaymentsReported()
        },

        async getResume () {
            const response = await PaymentService.getResume()
            const status = response.status
            const result = await response.json()

            if (status != 200) {
                console.log("Ocurrio un error");
                return
            }

            this.availableAmount = result.amount_available
            delete result.amount_available
            this.resume = result
        },

        async getPaymentsPending () {

            this.loading = true

            const response = await PaymentService.get({status: "pending", order_by: "expiration_date", order_by_condition: "asc", paginate: 4})
            const status = response.status
            const result = await response.json()
            
            if (status != 200) {
                console.log("Ocurrio un error")
                return
            }

            console.log(result.data)

            this.payments = result.data
            this.loading = false
        },

        async getPaymentsExpired () {

            this.loading = true

            const response = await PaymentService.get({status_group: "expired", paginate: 4})
            const status = response.status
            const result = await response.json()

            if (status != 200) {
                console.log("Ocurrio un error")
                return
            }

            console.log(result.data)

            this.paymentsExpired = result.data
            this.loading = false
        },

        async getPaymentsReported () {

            this.loading = true

            const response = await PaymentService.get({status: "paid_by_mangxo", order_by: "expiration_date", order_by_condition: "asc", paginate: 4})
            const status = response.status
            const result = await response.json()

            if (status != 200) {
                console.log("Ocurrio un error")
                return
            }

            console.log(result.data)

            this.paymentsReported = result.data
            this.loading = false
        },

        async getTotalPaymentsByUser () {
            const response = await PaymentService.getTotalPaymentsByUser()
            const status = response.status
            const result = await response.json()
            
            if (status == 200) {
                this.totalPayments = result.total
            }
        },

        handleActionPayment (item, code) {
  
            if (code == "pending") {
                this.item = item
                this.reportPaymentDialog = true
                return
            }

            if (code == "extend_term") { // To extend term
                this.item = item
                this.showDialogExtendTerm = true
                return
            }

            if (code == "request_extend_term") {
                this.item = item
                this.showDialogRequestExtendTerm = true
                return
            }

            if (code == "show_invoice") {
                this.item = item
                this.showInvoice();
            }

            this.setDialogData(code)
            // const action = this.payments[code].handleAction
            // this.dialogContent.action = () => this[action](item)
        },

        setDialogData (code) {
            const dialogTexts = getDialog(code)
            if (dialogTexts) {
                this.dialogContent.title = dialogTexts.title
                this.dialogContent.text = dialogTexts.text
                this.dialogContent.text_button = dialogTexts.text_button
                this.dialog = true  
            }
        },

        handleCloseDialog () {
            this.dialog = false  
            this.dialogContent.title = ""
            this.dialogContent.text = ""
            this.dialogContent.text_button = ""
            this.action = () => {}
        },

        handleCloseDialogAlert () {
            this.dialogAlert = false
            this.dialogAlertContent.title = ""
            this.dialogAlertContent.success = ""
            this.dialogAlertContent.text = ""
        },

        showDialogAlert(data) {
            console.log(data.success, data.msg)
            this.dialogAlertContent.title = ""
            this.dialogAlertContent.success = data.success
            this.dialogAlertContent.text = data.msg
            this.dialogAlert = true
            console.log(this.dialogAlertContent)
        },

        translate_code (key) {
            return translate_code(key)
        },

        //Suppliers
        async confirmPayment (item) {
            const response = await PaymentService.confirmPayment(item.id)
            const status = response.status
            const result = await response.json()

            if (status != 200) {
                console.log("Ocurrio un error al confirmar", result)
            }

            this.handleCloseDialog()

            this.showDialogAlert(result)

            this.init()
        },

        async reportNonPayment (item) {
            const response = await PaymentService.reportNonPayment(item.id)
            const status = response.status
            const result = await response.json()

            if (status != 200) {
                console.log("Ocurrio un error al confirmar", result)
            }

            this.handleCloseDialog()

            this.showDialogAlert(result)

            this.init()
        },

         async reportPayment (item) {
            const response = await PaymentService.reportPayment(item.id)
            const status = response.status
            const result = await response.json()

            if (status != 200) {
                console.log("Ocurrio un error al confirmar", result)
            }

            this.handleCloseDialog()

            this.showDialogAlert(result)

            this.init()
        },

        async rejectPayment(item) {
            const response = await PaymentService.rejectPayment(item.id)
            const status = response.status
            const result = await response.json()

            if (status != 200) {
                console.log("Ocurrio un error al rechazar el pago", result)
            }

            this.handleCloseDialog()

            this.showDialogAlert(result)

            this.init()
        },

        showTutorial() {
            if (this.$UserManager.getMainRole() == "supplier") {
                return
            }

            const buyTutorialShowed = localStorage.getItem("buyTutorialShowed")

            if (localStorage.getItem("buyTutorialShowed") === null) {
                localStorage.setItem("buyTutorialShowed", true)
                this.showDialog = true
            }

            if (buyTutorialShowed == true) {
                this.showDialog = false
            }
        },

        async showInvoice() {
            const response = await FileService.getFile(this.item.invoice_url)
            const blob = await response.blob()  
            const blobURL = URL.createObjectURL(blob);
            window.open(blobURL);
        }
    },

    filters: {
        currency(value) {
            
            var formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN'
            });
            
            return formatter.format(value);
        }
    }
}
</script>

<style>
.v-messages {
    display: none !important;
}

.v-input--selection-controls {
    margin-top: 0 !important;
    padding-left: 5px;
    padding-right: 5px;
}
</style>