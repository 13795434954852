<template>
        <div class="pa-2">
            <div class="text-h6 font-weight-bold deep-purple--text">Compra fiado con todos nuestros proveedores</div>
            <div class="slider mb-4" @click="$router.push({ name: 'where-buy'})">
                <div class="slide-track">
                    <div class="slide" v-for="supplier in suppliers" :key="supplier" style="display: flex; align-items: center;justify-content: center;margin-left: 3em; margin-right: 3em;">
                        <img 
                            :src="supplier" 
                            height=""
                            width="90" 
                            alt="" 
                        />
                    </div>
                    <div class="slide" v-for="supplier in suppliers" :key="supplier" style="display: flex; align-items: center; justify-content: center;margin-left: 3em; margin-right: 3em;">
                        <img 
                            :src="supplier" 
                            width="90" 
                            alt="" 
                        />
                    </div>
                </div>
            </div>
        </div>

</template>

<script>
    export default {
        data() {
            return {
                suppliers: [
					"https://mangxo.com/img/suppliers/slider/gama.png",
					"https://mangxo.com/img/suppliers/slider/ppa.jpeg",
					"https://mangxo.com/img/suppliers/slider/plafones-e-interiores.jpeg",
					"https://mangxo.com/img/suppliers/slider/regio-mecasa.png",
					"https://mangxo.com/img/suppliers/slider/potencia-fluida.png",
					"https://mangxo.com/img/suppliers/slider/magno-concretos.png",
					"https://mangxo.com/img/suppliers/slider/kirkot.png",
					"https://mangxo.com/img/suppliers/slider/aceros-escobedo.png",
					"https://mangxo.com/img/suppliers/slider/smart-blocks.png",
					"https://mangxo.com/img/suppliers/slider/mat-movil.png",
					"https://mangxo.com/img/suppliers/slider/soluciones-moviles.jpeg",
					"https://mangxo.com/img/suppliers/slider/tumatsa.png",
                    "https://mangxo.com/img/suppliers/surtidorelectrico.avif",
                    "https://mangxo.com/img/suppliers/mado.png",
                    "https://mangxo.com/img/suppliers/getsa.png"            
                ]
            }
        },

        methods: {}
    }
</script>

<style>
/* body {
	align-items: center;
	background: #E3E3E3;
	display: flex;
	height: 100vh;
	justify-content: center;
} */

/* @mixin white-gradient {
	background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}

$animationSpeed: 40s; */

@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-300px * 7))}
}

.slider {
	background: white;
	box-shadow: 0 10px 20px -5px  rgba(0, 0, 0, 0.050);
	/* height: 80px; */
	margin: auto;
	overflow:hidden;
	position: relative;
	/* width: 960px; */
	
	&::before,
	&::after {
		@include white-gradient;
		content: "";
		height: 100px;
		position: absolute;
		width: 200px;
		z-index: 2;
	}
	
	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}
	
	.slide-track {
		animation: scroll 20s linear infinite;
		display: flex;
		width: calc(250px * 14);
	}
	
	.slide {
		height: 100px;
		width: 250px;
	}
}
</style>