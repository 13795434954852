const UserManager = {

    store: (user) => {
        localStorage.setItem("user", JSON.stringify(user))
    },

    update: (data) => {
        const user = UserManager.get()

        for(let prop in data) {
            user[prop] = data[prop]
        }

        UserManager.store(user)
    },

    destroy: () => {
        localStorage.removeItem("user")
        localStorage.removeItem("token")
    },

    get () {
        return JSON.parse(localStorage.getItem("user"))
    },
    
    getRole () {
        return this.get()?.role_name ?? null
    },

    getId () {
        return this.get().id
    },
    
    getMainRole () {
        return this.get().owner_type.split("\\")[2].toLowerCase()
    },

    getBusinessType () {
        return this.get().business_type
    }
}

export default UserManager