<template>
    <div class="text-center">
      <v-dialog
          v-model="show"
          max-width="400"
        >
        <v-card>
            <v-card-title>Liberar línea reservada</v-card-title>
            <v-card-text>
                <v-col cols="12">
                    <div>Cliente: {{ selected.business_name }}</div>
                    <div>ID de reserva: {{ selected.id }}</div>
                    <div>Monto de línea: {{ selected.amount | currency }}</div>
                    <div><b>Disponible: {{ selected.available | currency }}</b></div>
                </v-col>
                <div v-if="message" class="red--text">{{message}}</div>
            </v-card-text>
            
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="close"
              >
                Cerrar
              </v-btn>
              <v-btn
                text
                 style="background: #732C9C; color: #fff"
                @click="handleSubmit"
                :disabled="loading"
              >
              {{ loading ? "Cargando..."  : "Confirmar" }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
  </template>
  
  <script>
  import ReservedCreditLineService from '../../services/reservedCreditLineService'
  
  export default {
      props: [
        "visible",
        "selected"
      ],
  
      data() {
          return {
            amount: null,
            term: null,
  
            loading: false,
            message: null,
            valid: false,
          }
      },
  
      methods: {
        
        async handleSubmit () {
         
          this.loading = true
  
          const response = await ReservedCreditLineService.deactivateLine(this.selected.id)
          const result = await response.json()
          const status = response.status
  
          if (status == 200) {
            this.$emit("showMessage", {success: true, msg: result.msg})
            this.close()
          } else {
            this.message = result.msg
          }
  
          this.loading = false
        },
  
        close () {
          this.reset()
          this.$emit('close')
        },
  
        reset() {
          this.amount = null
          this.term = null
          this.customer_id = null
          this.message = null
        }
      },
  
      computed: {
        show: {
          get () {
            return this.visible
          },
          set (value) {
            if (!value) {
              this.close()
            }
          }
        },
      },
  
      filters: {
          currency(value) {
              
              var formatter = new Intl.NumberFormat('es-MX', {
                  style: 'currency',
                  currency: 'MXN'
              });
              
              return formatter.format(value);
          }
      }
  }
  
  </script>