<template>
    <v-container class="text-center">
        <div class="text-h4 font-weight-bold mb-4">¡Has finalizado tu solicitud!</div>
        <v-icon
            style="font-size: 80px !important; color: rgb(115, 46, 213);"
        >
            mdi mdi-check-circle
        </v-icon>
        <h2 class="mt-4">Te avisaremos al correo registrado cuando hayamos finalizado con la evaluación</h2>
    </v-container>
</template>

<script>
    export default {
        props: ["email"],
        created() {
            this.$emit("saveStep", "register_completed")
            localStorage.removeItem("register_token")
        }
    }
</script>