<template>
    <v-col
        cols="12"
    >
        <div style="color:red;" class="text-center">{{ errorMessage }}</div>
    </v-col>
</template>

<script>
    export default {
        props: ["errorMessage"],
    }
</script>