<template>
    <v-main>
        <v-container class="py-8 px-6">
            <v-card class="py-8 px-6">
                <v-tabs>
                    <v-tab href="#businessInformation">
                        Información del negocio
                    </v-tab>
                    <v-tab-item value="businessInformation">
                        <BusinessInformationComponent :value="businessInformation"/>
                    </v-tab-item>
                    <v-tab href="#bankAccounts">
                        Cuentas bancarias
                    </v-tab>
                    <v-tab-item value="bankAccounts">
                        <BankAccountsComponent :value="businessInformation.bank_accounts" @updateBusinessInformation="getBusinessInformation"/>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-container>
    </v-main>
</template>

<script>

import BusinessInformationComponent from '@/components/profile/BusinessInformationComponent'
import BankAccountsComponent from '@/components/profile/BankAccountsComponent'
import SupplierService from "@/services/supplierService"

export default {

    components: {
        BusinessInformationComponent,
        BankAccountsComponent
    },

    data () {
        return {
            businessInformation: {}
        }
    },

    async mounted () {
        await this.getBusinessInformation()
    },

    methods: {
        async getBusinessInformation() {
            const response = await SupplierService.getBusinessInformation()
            const status = response.status
            
            if (status != 200) {
                console.log("Ocurrio un error")
                return
            }

            const result = await response.json()
            this.businessInformation = result

            console.log(this.businessInformation)
        }
    }
}

</script>
