<template>
<v-main>
    <v-container
    class="py-8 px-6"
    
    >
        <v-row>
            <v-col
            lg="6"
            offset-lg="3"
            md="12"
            align="center"
            
            >
            <div class="text-h6 mb-5">Tu número de usuario: {{ $UserManager.getId() }}</div>
              <v-card>
                <v-card-title class="justify-center">
                    Código de compra
                </v-card-title>
                <div class="text-h3 mt-3 mb-4" v-if="response.token">
                    {{ response.token }}
                </div>
                <v-col>
                    <v-btn
                        class="yellow amber lighten-1--text"
                        @click="handleGenerateToken"
                    >
                        Generar 
                    </v-btn>
                </v-col>
                 <v-col>
                    <v-btn
                        :color="copyButton.color"
                        outlined
                        @click="copyData"
                    >
                    <v-icon
                        color="light"
                    >
                        mdi {{ copyButton.icon }}
                    </v-icon>
                        {{ copyButton.text }} 
                    </v-btn>
                </v-col>
                
                 <div class="text-h6 mt-6" v-if="response.expire_in">
                    Expirara en: {{ minutes }}:{{ seconds <= 9 ? '0' + seconds : seconds}}
                </div>
              </v-card>
              <SharedToken />
            </v-col>
        </v-row>
    </v-container>
</v-main>
</template>

<script>

import CustomerTokenService from '../services/customerTokenService'
import SharedToken from '../components/SharedToken.vue'

export default {

    components: {
        SharedToken
    },

    data() {
        return {
            response: {},
            active: false,
            minutes: 0,
            seconds: 0,
            copyButton: {
                text: "Copiar datos de compra",
                color: "light",
                icon: "mdi-clipboard"
            } 
        }
    },

    mounted () {
        setInterval(this.updateCounter, 1000)

        this.handleGenerateToken()
    },

    methods: {
        async handleGenerateToken() {
            const response = await CustomerTokenService.generate()
            const status = response.status
            const result = await response.json()

            if (status == 200) {
                this.response = result
                this.resetCopyButton()
            }
        },

        updateCounter () {
            this.seconds -= 1
            if (this.seconds == 0) {
                this.seconds = 59
                this.minutes -=1 
            }

            if (this.minutes == 0) {
                return
            }
        },
        
        copyData() {
            const data = "Número de usuario: " + this.$UserManager.getId() + '\n' + "Código de compra: " + this.response.token
            navigator.clipboard.writeText(data);

            this.copyButton.text = "Datos copiados"
            this.copyButton.color = "success"
            this.copyButton.icon = "mdi-clipboard-check"
        },

        resetCopyButton() {
            this.copyButton = {
                text: "Copiar datos de compra",
                color: "light",
                icon: "mdi-clipboard"
            } 
        }
    },

    watch: {
        response() {
            this.minutes = (this.response.expire_in / 60) - 1
            this.seconds = 59;
        }
    }
}
</script>
