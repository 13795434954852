<template>
   <v-container>
        <v-col cols="12">
            <v-row class="flex justify-space-between">
                <div class="title">Información bancaria</div>
                
                <v-btn
                    v-if="$UserManager.getMainRole() == 'supplier'"
                    color="primary"
                    outlined
                    @click="showBankAccountForm = !showBankAccountForm"
                >
                    AGREGAR
                </v-btn>
            
            </v-row>
        </v-col>

        <v-row class="mt-4"  v-for="(value, key, index) in accounts" :key="index" >  
            <v-col>
                <div class="grey--text font-weight-bold">CUENTA EN {{ value.currency.toUpperCase() }} - {{ value.bank_name.toUpperCase() }} </div>
                <div class="grey--text">NÚMERO DE CUENTA: {{ value.account_number }}</div>
                <!-- <div class="grey--text">SUCURSAL: 7013</div> -->
                <div class="grey--text">CLABE: {{ value.account_key }}</div>
                <div class="grey--text">BENEFICIARIO: {{ value.beneficiary_name }}</div>
                <v-col class="mt-2" v-if="$UserManager.getMainRole() == 'supplier'">
                    <v-row>
                        <v-btn
                            color="primary"
                            outlined
                            @click="handleEdit(value)"
                        >
                            EDITAR
                        </v-btn>
                        <v-btn
                            color="red"
                            class="ml-2"
                            outlined
                        >
                            ELIMINAR
                        </v-btn>
                    </v-row>
                </v-col>
            </v-col>
        </v-row>
        <BankAccountsFormComponent 
            :visible="showBankAccountForm" 
            :isCreateForm="isCreateForm"
            :account="selectedAccount"
            @close="handleCloseBankAccountForm" 
            @updateBusinessInformation="updateBusinessInformation" 
        />
   </v-container>
</template>

<script>

import BankAccountsFormComponent from '@/components/profile/bankAccounts/FormComponent'

export default { 
    props: ["value"],
    components: {
        BankAccountsFormComponent
    },

    data() {
        return {
            showBankAccountForm: false,
            isCreateForm: true,
            selectedAccount: null
        }
    },

    methods: {
        handleEdit(account) {
            this.isCreateForm = false
            this.showBankAccountForm = true
            this.selectedAccount = account
        },

        handleCloseBankAccountForm() {
            this.showBankAccountForm = false
            this.isCreateForm = true
            this.selectedAccount = null

        },

        updateBusinessInformation() {
            this.$emit('updateBusinessInformation')
        }
    },  

    computed: {
        accounts: {
            get () {
                return this.value
            }
        }
    }
}

</script>
