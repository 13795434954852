<template>
    <v-main>
        <v-container
        class="py-8 px-6"
        >
            <v-row class="">
                <v-col cols="12">
                    <div class="text-h5 text-center mb-4">Mis proveedores</div>
                    <v-data-table
                        :headers="headers"
                        :items="suppliers"
                        hide-default-footer
                        class="elevation-1"
                        :loading="loading"
                        loading-text="Cargando..."
                    >
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn  
                                text
                                @click="$router.push({ name: 'supplier', params: {id: item.id} })"
                                color="primary"
                                outlined
                            >
                            Ver
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import CustomerService from '@/services/customerService'

export default {

    data() {
        return {
            suppliers: [],
            headers: [{"text": "Nombre", "value": "name"}, { text: 'Perfil', value: 'actions', sortable: false }],
            loading: false,            
        }
    },

    async mounted () {
        await this.getSuppliers()
    },

    methods: {
        async getSuppliers() {
            this.loading = true

            const response = await CustomerService.getSuppliers()
            const status = response.status
            const result = await response.json()
            
            if (status != 200) {
                console.log("Ocurrio un error")
                return
            }

            this.suppliers = result
            this.loading = false
        },

        getLinkToSupplier(id) {
            return `/supplier/${id}`
        }
    }
}
</script>
