<template>
    <v-main>
        <v-container class="py-8 px-6">  
            <v-col cols="12 mb-4">
                <v-row class="d-flex justify-space-between align-center">
                    
                    <div class="text-h5 align-center"> 
                        <v-icon
                            @click="$router.push({ name: 'suppliers' })"
                            large
                            color="grey"
                            >   
                            mdi-arrow-left    
                        </v-icon>
                        {{ businessInformation.business_name.toUpperCase() }} 
                    </div>
                    <v-btn v-if="businessInformation.phone"
                        color="white" 
                        fab
                        :href="getLinkToWpp()" 
                        target="_blank"
                    >
                        <v-icon
                        large
                        color="green"
                        >   
                        mdi-whatsapp    
                        </v-icon>
                    </v-btn>
                        
                </v-row>
            </v-col>

            <v-card class="py-8 px-6">
                <v-tabs>
                    <v-tab href="#businessInformation">
                        Información
                    </v-tab>
                    <v-tab-item value="businessInformation">
                        <BusinessInformationComponent :value="businessInformation"/>
                    </v-tab-item>
                    <v-tab href="#bankAccounts">
                        Cuentas bancarias
                    </v-tab>
                    <v-tab-item value="bankAccounts">
                        <BankAccountsComponent 
                            :value="businessInformation.bank_accounts" 
                            @updateBusinessInformation="getBusinessInformation"
                        />
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-container>
    </v-main>
</template>

<script>

import BusinessInformationComponent from '@/components/profile/BusinessInformationComponent'
import BankAccountsComponent from '@/components/profile/BankAccountsComponent'
import SupplierService from "@/services/supplierService"

export default {

    components: {
        BusinessInformationComponent,
        BankAccountsComponent
    },

    data () {
        return {
            businessInformation: {},
            supplierId: null
        }
    },

    created(){ 
        this.supplierId = this.$route.params.id
    },

    async mounted () {
        await this.getBusinessInformation()
    },

    methods: {
        async getBusinessInformation() {
            const response = await SupplierService.getBusinessInformation(this.supplierId)
            const status = response.status
            
            if (status != 200) {
                console.log("Ocurrio un error")
                return
            }

            const result = await response.json()
            this.businessInformation = result
        },
        getLinkToWpp() {
            return `https://wa.me/${this.businessInformation.phone}`
        }
    }
}

</script>
