<template>
    <div class="text-center">
      <v-dialog
          v-model="show"
          max-width="700"
        >
            <v-card>
                <v-card-title style="display: flex; justify-content: space-between;">
                    Nueva venta por Whatsapp
                    <v-chip
                        class="ma-2 yellow amber"
                        color="white"
                        medium
                    >
                        <span style="color: #fff !important;" class="font-weight-bold">Nuevo</span>
                        <v-icon right color="#ffff">
                        mdi-star
                        </v-icon>
                    </v-chip>

                </v-card-title>
                <v-card-text style="margin-top: 2em;">
                    <p style="font-size: 1.2em; line-height: 1.2em;" class="font-weight-bold">Ahora puedes realizar las ventas por WhatsApp sin necesidad de solicitar el código de compra.</p>
                    <p style="font-size: 1.2em; line-height: 1.2em;" class="font-weight-bold">Solo necesitas hacer clic en "Realizar venta por WhatsApp" y la compra se enviará al cliente, quien podrá confirmarla o rechazarla.</p>
                    <p style="font-size: 1.2em; line-height: 1.2em;" class="font-weight-bold">A continuación, te mostramos en esta imagen dónde se encuentra esta opción:</p>
                    <v-img src="@/assets/frontdesk/wpp-purchase-info.png" max-width="100%"/>
                </v-card-text>
                <v-card-actions style="display: flex; justify-content: end;">
                    <v-btn
                    color="grey lighten-1--text"
                    class="mr-4"
                    medium
                    outlined
                    @click="$emit('close')"
                  >
                    CERRAR
                  </v-btn>
                  </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
  </template>
  
  <script>
  export default {
    props: ["visible"],

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.close()
          }
        }
      },
    }
  }
  </script>