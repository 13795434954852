/* eslint-disable */

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import router from './router'
import UserManager from './services/manager/UserManager'
import "./plugins/vuetify-money.js";

Vue.config.productionTip = false
Vue.prototype.$UserManager = UserManager
Vue.use(VueRouter)
Vue.use(UserManager)

new Vue({
  vuetify,
  render: h => h(App),
  router, 
}).$mount('#app')

