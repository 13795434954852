<template>
    <v-icon
        color="light"
        small
        @click="copyData"
        title="Copiar"
    >
        mdi mdi-clipboard
    </v-icon>
</template>
<script>
    export default {
        props: ["content", "color", "icon"],

        data() {
            return {}
        },

        methods: {
            copyData() {
                navigator.clipboard.writeText(this.content);
            },
        }
    }
</script>