<template>
   <v-container>
    
        <div class="title" v-if="$UserManager.getMainRole() == 'supplier'">Terminos con Mangxo</div>

        <v-row class="mt-4" v-if="$UserManager.getMainRole() == 'supplier'"> 
            <v-col cols="4" class="grey--text">
                Limite de linea de credito: {{ businessInformation.credit_limit | currency }}
            </v-col>
            <v-col cols="4" class="grey--text">
                Limite de transacción: {{ businessInformation.transaction_limit | currency }}
            </v-col>

            <v-col cols="4" class="grey--text">
                Limite de plazo: {{ businessInformation.term_limit }} días.
            </v-col>
        </v-row>

        <div class="title mt-4">Información fiscal</div>
    
        <v-row class="mt-4"> 
            <v-col cols="4" class="grey--text">
                RFC: {{ businessInformation.tax_id }}
            </v-col>
            <v-col cols="4" class="grey--text">
                Razón Social: {{ businessInformation.business_name }}
            </v-col>
        </v-row>
        <v-row class="mt-4"> 
            <v-col cols="4" class="grey--text">
                Tipo de persona: Persona Moral
            </v-col>
            <v-col cols="4" class="grey--text">
                Responsable Legal: {{ businessInformation.legally_responsible }}
            </v-col>
            <v-col cols="4" class="grey--text">
                Dirección Fiscal: {{ businessInformation.address }}
            </v-col>
        </v-row>

        <div class="title mt-4">Información de contacto</div>
    
        <v-row class="mt-4"> 
            <v-col>
                <div class="grey--text">CONTACTO: {{ businessInformation.contact_name }}</div>
                <div class="grey--text">TELEFONO: {{ businessInformation.phone }}</div>
                <div class="grey--text">CORREO: {{ businessInformation.email }}</div>
            </v-col>
        </v-row>
   </v-container>
</template>

<script>

export default {  
    props: ["value"],

    data() {
        return {}
    },

    computed: {
        businessInformation: {
            get () {
                return this.value
            }
        }
    },

    filters: {
        currency(value) {
            
            var formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN'
            });
            
            return formatter.format(value);
        }
    }
}

</script>
