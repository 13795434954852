<template>
  <div class="text-center">
    <v-dialog
      v-model="show"
      @click:outside="close"
      max-width="400"
    >
      <v-card>
        <v-container v-if="item">
              <v-card-title class="text-h5">
                Adjuntar factura
              </v-card-title>

              <v-card-text class="mt-4">
                <v-row>
                  <v-col cols="5">
                    <div>
                      ID transacción: {{ item.id ?? "-" }}
                    </div>
                    <div>
                      Monto: ${{ item.amount ?? "-" }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>
              <v-form>
                  <v-file-input
                    label="Factura"
                    v-model="files"
                    required
                    multiple="multiple"
                  ></v-file-input>
              </v-form>
              <div class="red--text">
                {{ errorMessage }}
              </div>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="success"
            @click="handleSubmit"
            :disabled="inSubmit"
          >
            Confirmar
          </v-btn>
          <div v-if="inSubmit">Cargando...</div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import PaymentService from "../services/paymentService"

  export default {
    props: ['visible', 'item'],

    data: () => ({
        files: [],
        errorMessage: '',
        inSubmit: false
    }),

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.$emit('close')
          }
        }
      }
    },

    methods: {
      async handleSubmit() {

          this.inSubmit = true

          if (this.files.length == 0) {
            this.errorMessage = "Debe seleccionar un archivo"
            return
          }

          var formdata = new FormData();
          formdata.append("payment_id", this.item.id);

          for(let i=0; i < this.files.length; i++) {
            formdata.append("files[]", this.files[i]);
          }

          const response = await PaymentService.attachInvoice(formdata)
          const status = response.status
          const result = await response.json()

          switch(status) {
            case 200:
              if (!result.success) {
                this.errorMessage = result.msg
                return
              }

              this.$emit("success", result)
              this.close()
            break
            case 500:
              this.errorMessage = "Ocurrio un error"
            break
            case 404:
              this.errorMessage = result.msg
            break
            case 422:
              this.errorMessage = "Debe cargar un archivo"
            break
              
          }

          this.inSubmit = false

      },

      close() {
        this.valid = false
        this.file = null
        this.errorMessage = null
        this.inSubmit = false
        this.$emit('close')
      },
    }
  }
</script>