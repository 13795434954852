<template>
<div 
    class="d-flex align-center justify-center flex-column loader"
>

    <v-progress-circular
    :size="100"
    color="rgb(115, 46, 213)"
    indeterminate
    >
    <img src="@/assets/logo.png" width="70px"/>
    </v-progress-circular>
    <v-container class="text-center">
        <h3 class="mt-4">En Mangxo puedes utilizar tu crédito con cualquier proveedor de la red</h3>
    </v-container>
</div>
</template>

<script>
    export default {
        name: "loading-component",
        
        data() {
            return {}
        }
    }
</script>

<style>
    .loader {
        position: absolute;
        background: white;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }
</style>