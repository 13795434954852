const StatusCustomerService = {

    url: `${process.env.VUE_APP_API_URL}status-customer`,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },

    create: async (step) => {
        try {
            const headers = StatusCustomerService.headers
            headers['Register_Token'] = localStorage.getItem('register_token')

            const response = await fetch(`${StatusCustomerService.url}/create`, {
                method: 'POST',
                body: JSON.stringify({step}),
                headers: StatusCustomerService.headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },
}

export default StatusCustomerService