<template>
<div>
    <v-row class="mt-6">
        <v-col
            lg="6"
            offset="3"
            
            >
            <v-card>
                <v-card-title>
                    
                </v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col class="text-center">
                            <div class="text-h5 mt-2 font-weight-medium" style="color: #732C9C;" v-if="result.success">
                                {{result.msg}}
                                <br>

                                <v-btn
                                    v-if="showAttachInvoiceButton"
                                    class="yellow amber lighten-1--text mt-2"
                                    @click='showAttachInvoice = true'
                                >
                                   Adjuntar factura
                                </v-btn>    
                            </div>
                            <div class="text-h5 mt-2 red--text font-weight-medium" v-if="!result.success">¡COMPRA RECHAZADA!</div>

                            <div class="text-h5 mt-2 red--text font-weight-medium" v-if="!result.success && result.msg">{{ result.msg }}</div>
                            
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12 text-center">
                            <div v-if="!result.success">
                                <v-btn
                                    class="light mt-2"
                                    @click='$emit("goBack")'
                                >
                                   Editar compra
                                </v-btn>
                            </div>
                            <div class="mt-2" v-if="result.success">
                                <v-btn
                                    class="light"
                                    @click="generateCreditCheck()"
                                    :disabled="isGeneratingPdf"
                                >
                                   Imprimir comprobante
                                </v-btn>
                            </div>
                            <div class="mt-2">
                                <span v-if="isGeneratingPdf">Generando comprobante...</span>
                            </div>
                            <div class="mt-2">
                                <v-btn
                                    v-if="$UserManager.getRole() == 'admin-supplier'"
                                    class="light"
                                    @click="$router.push({ name: 'dashboard'})"
                                >
                                   Volver a inicio
                                </v-btn>
                                 <v-btn
                                    v-if="$UserManager.getRole() == 'vendor-supplier'"
                                    class="light mt-2"
                                    @click='$emit("goBack", true)'
                                >
                                   Volver
                                </v-btn>
                            </div>
                            <div class="mt-2">
                            <v-btn
                                class="deep-purple lighten-4"
                                target="_blank"
                                href="https://walink.co/31d4e2"
                            >
                                Contactar un asesor
                            </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <AttachInvoice :visible="showAttachInvoice" :item="result.data" @close="showAttachInvoice = false" @success="dialogAlert = true; showAttachInvoiceButton = false"/>
    <DialogAlert :visible="dialogAlert" :message="dialogAlertContent" @handleCloseDialogAlert="dialogAlert = false"/>
</div>
</template>

<script>
import PaymentService from '../../services/paymentService'
import AttachInvoice from '../AttachInvoice'
import DialogAlert from "../DialogAlert"

export default {
    props: ["result"],

    components: {
        AttachInvoice,
        DialogAlert
    },

    data() {
        return {
            isGeneratingPdf: false,
            showAttachInvoiceButton: true,
            showAttachInvoice: false,
            dialogAlert: false,
            dialogAlertContent: {
                title: "Factura",
                success: true,
                text: "Factura adjunta con exito"
            },
        }
    },

    methods: {
        async generateCreditCheck() {

            this.isGeneratingPdf = true
            const response = await PaymentService.generateCreditCheck(this.result.data.id)

            if (!response) {
                console.log("Ocurrio un error. Exception")
            }

            const status = response.status

            if (status == 500) {
                console.log("Ocurrio uun error")
            }

            if (status == 404) {
                console.log("No se encontro el pago")
            }

            if (status == 200) {

                const file = await response.blob()

                const aElement = document.createElement('a');
                aElement.setAttribute('download', 'credit-check.pdf');
                const href = URL.createObjectURL(file);
                aElement.href = href;
                aElement.setAttribute('target', '_blank');
                aElement.click();
                URL.revokeObjectURL(href);
            }

            this.isGeneratingPdf = false
        }
    }
}

</script>
