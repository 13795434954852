<template>
<v-main>
    <v-container
    class="py-8 px-6"
    
    >

     <v-row>
        <v-col
        lg="6"
        offset-lg="3"
        md="12"
        >
          <v-img
            max-height="150"
            max-width="250"
            src="@/assets/logo.webp"
            class="mx-auto"
            ></v-img>

            <div class="pa-6 mt-6 text-center">
                <div class="text-h4">Te has registrado!</div>
                <div class="text-h5 mt-4">Te informaremos cuando tu cuenta este activa.</div>
                <v-btn 
                    class="yellow amber lighten-1--text mt-4"
                    href="https://mangxo.com"
                >
                    Volver al sitio
                </v-btn>
            </div>
        </v-col>
     </v-row>
    </v-container>
</v-main>
</template>

<script>

export default {
    data: () => ({
    }),

    methods: {}
}
</script>

<style scoped>
.v-main {
    background-size: cover;
    position: relative !important; 
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size:cover;
    background-attachment: fixed;
    background-position: center;
    background-image: linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('https://mangxo.com/img/bg-header.jpeg');
}
</style>